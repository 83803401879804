import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  approveButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  searchButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  rejectButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

function RecurringApproval() {
  const token = localStorage.getItem("token");
  const [branches, setBranches] = useState([]);
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  console.log("data", data);
  const [filteredData, setFilteredData] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  const fetchData = (type) => {
    let url;
    switch (type) {
      case "regular":
        url =
          "https://api.vlnidhi.com/api/admin/monthly/installments/pending";
        break;
      case "daily":
        url =
          "https://api.vlnidhi.com/api/admin/daily/installments/pending";
        break;
      case "flexi":
        url =
          "https://api.vlnidhi.com/api/admin/flexy/installments/pending";
        break;
      default:
        console.error("Invalid type selected");
        return;
    }
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
        setSelectedIds([]);
        setSelectAll(false);
      })
      .catch((error) => console.error(`Error fetching ${type} data:`, error));
  };

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setType(selectedType);
    fetchData(selectedType);
    // setShowTable(false);
  };

  const applyFilters = () => {
    if (!data || data.length === 0) {
      // Show toast message for null or empty data
      toast.error("No data available to filter!");
      return;
    }
    const filtered = data.filter((item) => {
      // Determine the branch name based on type
      const branch =
        type === "flexi" ? item.branch.branchName : item.branchName;

      const isBranchMatch = branchName ? branch === branchName : true;

      // Determine the date field based on type
      const dateField =
        type === "flexi" ? item.transactionDate : item.renewalDate;

      const isFromDateMatch = fromDate
        ? new Date(dateField) >= new Date(fromDate)
        : true;
      const isToDateMatch = toDate
        ? new Date(dateField) <= new Date(toDate)
        : true;

      return isBranchMatch && isFromDateMatch && isToDateMatch;
    });

    setFilteredData(filtered);
    setShowTable(true);
  };

  const handleRowSelect = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const ids = filteredData.map(
        (item) => item.txnId
        // type === "daily" ? item.txnId : item.transactionId
      );
      setSelectedIds(ids);
    } else {
      setSelectedIds([]);
    }
  };

  const handleApprove = () => {
    let url;
    switch (type) {
      case "regular":
        url =
          "https://api.vlnidhi.com/api/admin/monthly/approveInstallments";
        break;
      case "daily":
        url = "https://api.vlnidhi.com/api/admin/daily/approve";
        break;
      case "flexi":
        url = "https://api.vlnidhi.com/api/admin/flexy/approve";
        break;
      default:
        console.error("Invalid type selected");
        return;
    }
    const payload = { txnIds: selectedIds };
    axios
      .put(url, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Approval successful!",
          icon: "success",
          confirmButtonText: "OK",
        });
        fetchData(type);
        setSelectedIds([]);
        setSelectAll(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Failed to approve installments.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleReject = () => {
    let url;
    switch (type) {
      case "regular":
        url = "https://api.vlnidhi.com/api/admin/monthly/reject";
        break;
      case "daily":
        url = "https://api.vlnidhi.com/api/admin/daily/reject";
        break;
      case "flexi":
        url = "https://api.vlnidhi.com/api/admin/flexy/reject";
        break;
      default:
        console.error("Invalid type selected");
        return;
    }

    const payload = { txnIds: selectedIds };
    axios
      .put(url, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Installment Rejected!",
          icon: "success",
          confirmButtonText: "OK",
        });
        fetchData(type);
        setSelectedIds([]);
        setSelectAll(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Failed to reject installments.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <Box>
      <Typography sx={styles.header}>Renewal Approval</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Type :</Typography>
              <TextField
                select
                value={type}
                onChange={handleTypeChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                <MenuItem value="regular">Regular</MenuItem>
                <MenuItem value="flexi">Flexi</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchName}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              onClick={applyFilters}
              sx={{
                ...styles.button,
                ...styles.searchButton,
                mt: { md: 2.3, xs: 1 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {showTable &&
        (filteredData.length > 0 ? (
          <Paper sx={{ ...styles.paper, mt: 2 }}>
            <Typography sx={styles.sectionHeader}>Policy Data List</Typography>
            <Box sx={{ overflowX: "auto" }}>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "20px",
                  width: { md: "100%", xs: "296px" },
                  overflowX: "auto",
                }}
              >
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#413AF1" }}>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          sx={{ color: "white" }}
                        />
                      </TableCell>
                      {[
                        "Sl No",
                        "Policy No",
                        "Branch",
                        "Renewal Date",
                        "Collector Code",
                        "INO",
                        "Term",
                        "Plan Code",
                        "Amount",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            color: "white",
                            fontWeight: 400,
                            fontSize: "13px",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((item, index) => {
                      const id = item.txnId;
                      // type === "daily" ? item.txnId : item.txnId;
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Checkbox
                              checked={selectedIds.includes(id)}
                              onChange={() => handleRowSelect(id)}
                            />
                          </TableCell>

                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.policyCode || "-"}</TableCell>
                          {type === "flexi" ? (
                            <>
                              <TableCell>
                                {item?.addInvestMent?.branch?.branchName || "-"}
                              </TableCell>
                              <TableCell>
                                {new Date(
                                  item?.transactionDate || "-"
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}
                              </TableCell>
                              <TableCell>
                                {item?.addInvestMent?.addCollector
                                  ?.advisorCode || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.addInvestMent?.installmentNo || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.addInvestMent?.term || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.addInvestMent?.schemeName || "-"}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item?.branchName || "-"}</TableCell>
                              <TableCell>
                                {new Date(
                                  item?.renewalDate || "-"
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}
                              </TableCell>
                              <TableCell>{item?.advisorCode || "-"}</TableCell>
                              <TableCell>
                                {item?.installmentNo || "-"}
                              </TableCell>
                              <TableCell>{item?.term || "-"}</TableCell>
                              <TableCell>{item?.planCode || "-"}</TableCell>
                            </>
                          )}
                          <TableCell>{item.amount || "-"}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  onClick={handleApprove}
                  disabled={selectedIds.length === 0}
                  sx={{
                    ...styles.button,
                    ...styles.approveButton,
                    "&:disabled": {
                      background: "#C0C0C0",
                    },
                  }}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleReject}
                  disabled={selectedIds.length === 0}
                  sx={{
                    ...styles.button,
                    ...styles.rejectButton,
                    "&:disabled": {
                      background: "#C0C0C0",
                    },
                  }}
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              marginTop: 2,
              color: "gray",
              fontSize: "18px",
            }}
          >
            No Pending Approval
          </Typography>
        ))}
    </Box>
  );
}

export default RecurringApproval;

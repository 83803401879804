import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Box,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";

const SavingDepositWithdraw = () => {
  const [closingBranches, setClosingBranches] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  // const [showTable, setShowTable] = useState(false);
  const [formData, setFormData] = useState({
    paymentBy: "",
    txnDate: "",
    closingBranch: "",
    accountNumber: "",
    memberCode: "",
    memberName: "",
    mobileNo: "",
    sbPlanName: "",
    availableBalance: "",
    transactionFor: "",
    remarks: "",
    txnType: "",
    amount: "",
    transactionType: " ",
    transactionDate: new Date().toISOString().split("T")[0],
  });
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch saving accounts
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const formattedAccountOptions = response.data.map((account) => ({
          value: account.savingAccountNumber,
          label: `${account.member?.memberName || "Unknown Member"} - ${
            account.savingAccountNumber
          }`,
          accountData: account,
        }));
        setAccountOptions(formattedAccountOptions);
      })
      .catch((error) =>
        console.error("Error fetching account numbers:", error)
      );

    // Fetch closing branches
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setClosingBranches(response.data))
      .catch((error) =>
        console.error("Error fetching closing branches:", error)
      );
  }, []);

  const handleAccountChange = async (event) => {
    const accountNumber = event.target.value;
    const account = accountOptions.find((acc) => acc.value === accountNumber);
    if (account) {
      const { accountData } = account;
      setSelectedAccount(accountData);
      setFormData({
        ...formData,
        accountNumber: accountData.savingAccountNumber,
        memberCode: accountData.member?.memberCode || "",
        memberName: accountData.member?.memberName || "",
        mobileNo: accountData.member?.mobileNo || "",
        sbPlanName: accountData.selectPlan || "",
        openingAmount: accountData.openingAmount || "0.00",
      });

      // Fetch transactions for the selected account
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://api.vlnidhi.com/api/admin/transactions/getpassbook/${accountNumber}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTransactions(response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "https://api.vlnidhi.com/api/admin/transactions/make-transaction",
        {
          transactionDate: formData.transactionDate,
          memberCode: formData.memberCode,
          branchCode: formData.closingBranch,
          savingAcoountNo: formData.accountNumber,
          mobileNumber: formData.mobileNo,
          jointHolder: formData.memberName,
          sbPlanName: formData.sbPlanName,
          transactionFor: formData.transactionFor,
          transactionType: formData.txnType,
          amount: formData.amount,
          remarks: formData.remarks,
          paymentBy: formData.paymentBy,
          transactionType: formData.transactionType,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Transaction successful");
      // alert("Transaction request successful, please wait for transaction approval!");
      Swal.fire({
        icon: "success",
        title: "Transaction request successful",
        text: `Please wait for transaction approval!`,
      });
    } catch (error) {
      console.error("Error making transaction:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Error making transaction: ${error}`,
      });
    }
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    // opacity: 0.6,
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          mb: 1,
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
        }}
      >
        Savings Deposit/Withdrawal
      </Typography>

      <Paper
        sx={{
          width: "100%",
          p: 3,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: 600,
            marginBottom: "2%",
            lineHeight: "36px",
            fontFamily: "Poppins",
          }}
        >
          Account Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Txn Date<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              type="date"
              name="transactionDate"
              value={formData.transactionDate}
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Select Branch Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                name="closingBranch"
                value={formData.closingBranch}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {closingBranches.map((branch) => (
                  <MenuItem key={branch.branchId} value={branch.branchCode}>
                    {branch.branchName} - {branch.branchCode}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Select Account Number<span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleAccountChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {accountOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Member Code<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              value={formData.memberCode}
              name="memberCode"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Member Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              name="memberName"
              value={formData.memberName}
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Mobile Number<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              SB Plan Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              name="sbPlanName"
              value={formData.sbPlanName}
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Save Button */}
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="center"
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Applicant Photo
            </Typography>
            <ImageUploadWrapper>
              <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="c"
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Signature
            </Typography>
            <ImageUploadWrapper>
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        sx={{
          width: "100%",
          p: 4,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          mt: 4,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Payment Details
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Ava. Balance <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              fullWidth
              name="openingAmount"
              value={formData.openingAmount}
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Transaction For<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="transactionFor" // Ensure name matches formData key
              value={formData.transactionFor} // Bind value to formData
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Remarks<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="remarks" // Ensure name matches formData key
              value={formData.remarks} // Bind value to formData
              onChange={handleFormChange} // Handle change to update formData
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Txn. Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                name="transactionType"
                value={formData.transactionType}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="CREDIT">Deposit</MenuItem>
                <MenuItem value="DEBIT">Withdrawal</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="number"
              name="amount"
              value={formData.amount}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (value > 0 || e.target.value === "") {
                  handleFormChange(e);
                }
              }}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Payment By<span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                id="paymentBy"
                name="paymentBy"
                value={formData.paymentBy}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                <MenuItem value="ONLINE">ONLINE</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ marginTop: "2%" }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#3029D9",
              width: "157px",
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
            }}
          >
            Save
          </Button>
        </Grid>
      </Paper>

      {/* Second Paper (Table Section) */}
      {transactions.length > 0 && (
        <Paper
          sx={{
            width: "100%",
            p: 2,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            mt: 4,
          }}
        >
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 1,
              }}
            >
              Scheme List
            </Typography>
            <Button>
              <DownloadIcon />
            </Button>
          </Grid>

          <TableContainer
            component={Paper}
            sx={{ marginTop: 2, borderRadius: "20px", width: { md: 1200, xs: 250} }}
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#413AF1" }}>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    INO
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    BRANCH
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    TXN DATE
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    A/C NO
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    CREDIT
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    DEBIT
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    BALANCE
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    PAY MODE
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    REMARKS
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    TXN ID
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Status
                  </TableCell>
                  {/* <TableCell sx={{ color: "white", textAlign: "center" }}>
                    USER ID
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
                    }}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.branchName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.transactionDate}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.accountNo}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.credit}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.debit}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.balanceAfterTransaction}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.paymentBy}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.remark}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.transactionId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.status}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center" }}>
                      {row.userId}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};

export default SavingDepositWithdraw;
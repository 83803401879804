import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

function AddCollector() {
  const [branches, setBranches] = useState([]);
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [positions, setPositions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);

  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    sectionHeader: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    label: {
      fontFamily: "Poppins, sans-serif",
      fontSize: { xs: "12px", md: "14px" },
    },
    textField: {
      width: "100%",
      borderColor: "#D9D9D9CC",
      backgroundColor: "#FFFFFF",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
    },
    button: {
      borderRadius: "10px",
      width: { xs: "100%", md: "128px" },
      height: { xs: "38px", md: "48px" },
      color: "white",
      textTransform: "none",
      fontWeight: "bold",
    },
    saveButton: {
      background: "#DFBF41",
      ":hover": {
        background: "#a18e46",
      },
    },
    deleteButton: {
      background: "#d73925",
      ":hover": {
        background: "#a1392c",
      },
    },
    newButton: {
      background: "#05ACD6",
      ":hover": {
        background: "#228097",
      },
    },
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age > 0 ? age : 0;
  };

  const fetchPinCodeData = async (pinCode, setFieldValue) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pinCode}`
      );
      const data = response.data;

      if (data[0].Status === "Success") {
        const postOfficeData = data[0].PostOffice[0];
        setDistrict(postOfficeData.District);
        setState(postOfficeData.State);

        // Set Formik values
        setFieldValue("district", postOfficeData.District);
        setFieldValue("state", postOfficeData.State);
      } else {
        console.error("Error: Invalid Pin Code");
        setDistrict("");
        setState("");
        setFieldValue("district", "");
        setFieldValue("state", "");
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error.message);
    }
  };

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchPositions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/positions/all");
      setPositions(response.data);
    } catch (error) {
      console.error("Error fetching positions:", error.message);
    }
  }, []);

  const fetchPaymentOptions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/payment-by");
      setPaymentOptions(response.data || []);
    } catch (error) {
      console.error("Error fetching payment options:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
    fetchPositions();
    fetchPaymentOptions();
  }, [fetchBranches, fetchPositions, fetchPaymentOptions]);

  const fetchIntroducerDetails = async (code, setFieldValue) => {
    try {
      const response = await axiosInstance.get(
        `/admin/Downline/details/${code}`
      );
      setFieldValue("introducerName", response.data.advisorName || "");
      setFieldValue("introPosition", response.data.position || "");
    } catch (error) {
      console.error("Error fetching introducer details:", error.message);
      setFieldValue("introducerName", "");
    }
  };
  const validationSchema = Yup.object({
    // smsSend: Yup.boolean(),
    fees: Yup.number().min(0, "Fees must be a positive number.").nullable(),
    remarks: Yup.string().max(500, "Remarks cannot exceed 500 characters."),
    nomineeName: Yup.string().required("Nominee Name is required."),
    nomineeRelation: Yup.string().required("Nominee Relation is required."),
    nomineeAge: Yup.number()
      .positive("Age must be a positive number.")
      .integer("Age must be an integer.")
      .required("Nominee Age is required."),
    nomineeMobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits.")
      .required("Nominee Mobile No. is required."),
    aadharNo: Yup.string()
      .matches(/^[0-9]{12}$/, "Aadhar number must be exactly 12 digits.")
      .required("Aadhar number is required."),
    panNo: Yup.string()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid PAN number format.")
      .required("PAN number is required."),
    // voterNo: Yup.string().matches(/^[A-Z0-9]{10}$/, "Invalid Voter ID format."),
    // .required("Voter number is required."),
    // rationNo: Yup.string().matches(
    //   /^[A-Z0-9]{10}$/,
    //   "Invalid Ration card number format."
    // ),
    // .required("Ration card number is required."),
    // dlNo: Yup.string().matches(
    //   /^[A-Z]{2}[0-9]{13}$/,
    //   "Invalid DL number format."
    // ),
    // .required("DL number is required."),
    mobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits.")
      .required("Mobile number is required."),
    email: Yup.string()
      .email("Invalid email format.")
      .required("Email is required."),
    gender: Yup.string().required("Gender is required."),
    maritalStatus: Yup.string().required("Marital Status is required."),
    address: Yup.string()
      .required("Address is required.")
      .min(10, "Address must be at least 10 characters."),
    joiningDate: Yup.string().required("Joining Date is required."),
    advisorTitle: Yup.string().required("Advisor Title is required."),
    advisorName: Yup.string()
      .required("Advisor Name is required.")
      .min(3, "Advisor Name must be at least 3 characters."),
    introducerCode: Yup.string().required("Introducer Code is required."),
    // .min(5, "Introducer Code must be at least 5 characters."),
    paymentBy: Yup.string().required("Please select a payment method."),
    dob: Yup.date()
      .required("Date of Birth is required")
      .test("isValidDate", "Date of Birth cannot be in the future", (value) => {
        return new Date(value) <= new Date();
      }),
    age: Yup.number().required("Age is required").min(0, "Invalid age"),
    branchCode: Yup.string().required("Branch is required"),
    pinCode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid Pin Code")
      .required("Pin Code is required"),
    positionName: Yup.string().required("Please select a position."),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      paymentBy: values.paymentBy,
      branchCode: values.branchCode,
      district: values.district,
      state: values.state,
      pinCode: parseInt(values.pinCode),
      dob: values.dob,
      age: values.age,
      positionName: values.positionName,
      introducerCode: values.introducerCode,
      introducerName: values.introducerName,
      joiningDate: values.joiningDate,
      advisorTitle: values.advisorTitle,
      advisorName: values.advisorName,
      gender: values.gender,
      maritalStatus: values.maritalStatus,
      address: values.address,
      mobileNo: parseInt(values.mobileNo),
      email: values.email,
      education: values.education,
      occupation: values.occupation,
      aadharNo: values.aadharNo,
      panNo: values.panNo,
      voterNo: values.voterNo,
      rationNo: values.rationNo,
      dlNo: values.dlNo,
      nomineeName: values.nomineeName,
      nomineeRelation: values.nomineeRelation,
      nomineeAge: parseInt(values.nomineeAge),
      nomineeMobileNo: parseInt(values.nomineeMobileNo),
      smsSend: values.smsSend,
      fees: values.fees ? parseFloat(values.fees) : 0,
      remarks: values.remarks,
    };

    try {
      const response = await axiosInstance.post("/admin/addCollector", payload);
      if (response.status === 201) {
        Swal.fire("Success", "Collector added successfully!", "success");
        resetForm();
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error submitting the form:", error.message);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to add collector/advisor.",
        "error"
      );
    }
  };

  return (
    <Formik
      initialValues={{
        branchCode: "",
        pinCode: "",
        district: "",
        state: "",
        paymentBy: "",
        positionName: "",
        dob: "",
        age: 0,
        introducerCode: "",
        introducerName: "",
        joiningDate: "",
        advisorTitle: "",
        advisorName: "",
        gender: "",
        maritalStatus: "",
        address: "",
        mobileNo: "",
        email: "",
        education: "",
        occupation: "",
        aadharNo: "",
        panNo: "",
        voterNo: "",
        rationNo: "",
        dlNo: "",
        nomineeName: "",
        nomineeRelation: "",
        nomineeAge: "",
        nomineeMobileNo: "",
        smsSend: false,
        fees: "",
        remarks: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, resetForm }) => (
        <Form>
          <Box>
            <Typography sx={styles.header}>Add Collector</Typography>

            <Paper sx={styles.paper}>
              <Typography sx={styles.sectionHeader}>
                Collector Details
              </Typography>

              <Grid container spacing={2}>
                {/* Joining Date */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Joining Date<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="joiningDate"
                      as={TextField}
                      type="date"
                      value={values.joiningDate}
                      onChange={(e) =>
                        setFieldValue("joiningDate", e.target.value)
                      }
                      error={touched.joiningDate && Boolean(errors.joiningDate)}
                      helperText={touched.joiningDate && errors.joiningDate}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* branchCode */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Branch Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="branchCode"
                      as={TextField}
                      select
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      value={undefined} // To ensure controlled value with Field
                      onChange={(e) =>
                        setFieldValue("branchCode", e.target.value)
                      }
                      error={touched.branchCode && Boolean(errors.branchCode)}
                      helperText={touched.branchCode && errors.branchCode}
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.branchCode}>
                          {`${branch.branchName} - ${branch.branchCode}`}
                        </MenuItem>
                      ))}
                    </Field>
                    {/* <TextField
                      select
                      sx={styles.textField}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                      value={selectedBranch}
                      onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.branchCode}>
                          {`${branch.branchName} - ${branch.branchCode}`}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </Box>
                </Grid>

                {/* Advisor Title */}
                <Grid item xs={12} md={1}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Title<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="advisorTitle"
                      as={TextField}
                      select
                      value={values.advisorTitle}
                      onChange={(e) =>
                        setFieldValue("advisorTitle", e.target.value)
                      }
                      error={
                        touched.advisorTitle && Boolean(errors.advisorTitle)
                      }
                      helperText={touched.advisorTitle && errors.advisorTitle}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="MR">Mr.</MenuItem>
                      <MenuItem value="MS">Ms.</MenuItem>
                      <MenuItem value="MRS">Mrs.</MenuItem>
                      <MenuItem value="Smt">Smt</MenuItem>
                      <MenuItem value="MD">MD</MenuItem>
                    </Field>
                  </Box>
                </Grid>

                {/* advisorName */}
                <Grid item xs={12} md={5}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Collector/Advisor Name
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="advisorName"
                      as={TextField}
                      value={values.advisorName}
                      onChange={(e) =>
                        setFieldValue("advisorName", e.target.value)
                      }
                      error={touched.advisorName && Boolean(errors.advisorName)}
                      helperText={touched.advisorName && errors.advisorName}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* gender */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Gender<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="gender"
                      as={TextField}
                      select
                      value={values.gender}
                      onChange={(e) => setFieldValue("gender", e.target.value)}
                      error={touched.gender && Boolean(errors.gender)}
                      helperText={touched.gender && errors.gender}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </Field>
                  </Box>
                </Grid>

                {/* maritalStatus */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Marital Status<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="maritalStatus"
                      as={TextField}
                      select
                      value={values.maritalStatus}
                      onChange={(e) =>
                        setFieldValue("maritalStatus", e.target.value)
                      }
                      error={
                        touched.maritalStatus && Boolean(errors.maritalStatus)
                      }
                      helperText={touched.maritalStatus && errors.maritalStatus}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="SINGLE">Single</MenuItem>
                      <MenuItem value="MARRIED">Married</MenuItem>
                      <MenuItem value="UNMARRIED">Unmarried</MenuItem>
                      <MenuItem value="DIVORCED">Divorced</MenuItem>
                      <MenuItem value="WIDOWED">Widowed</MenuItem>
                      <MenuItem value="SEPARATED">Separated</MenuItem>
                    </Field>
                  </Box>
                </Grid>

                {/* address */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Address<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="address"
                      as={TextField}
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* district */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      District<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="district"
                      as={TextField}
                      disabled
                      value={district}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* state */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      State<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="state"
                      as={TextField}
                      disabled
                      value={state}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* pinCode */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Pin Code<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="pinCode"
                      as={TextField}
                      onBlur={(e) =>
                        fetchPinCodeData(e.target.value, setFieldValue)
                      }
                      error={touched.pinCode && Boolean(errors.pinCode)}
                      helperText={touched.pinCode && errors.pinCode}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* dob */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ ...styles.label }}>
                          Date of Birth<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Field
                          name="dob"
                          as={TextField}
                          type="date"
                          value={values.dob}
                          onChange={(e) => {
                            const dob = e.target.value;
                            setFieldValue("dob", dob);
                            setFieldValue("age", calculateAge(dob));
                          }}
                          error={touched.dob && Boolean(errors.dob)}
                          helperText={touched.dob && errors.dob}
                          sx={styles.textField}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* age */}
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ ...styles.label }}>
                          Age(in years)
                        </Typography>
                        <Field
                          name="age"
                          as={TextField}
                          value={values.age}
                          disabled
                          sx={styles.textField}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                              background: "#eee",
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>

                {/* Mobile No. */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Mobile No.<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="mobileNo"
                      as={TextField}
                      value={values.mobileNo}
                      onChange={(e) =>
                        setFieldValue("mobileNo", e.target.value)
                      }
                      error={touched.mobileNo && Boolean(errors.mobileNo)}
                      helperText={touched.mobileNo && errors.mobileNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* Email */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Email<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="email"
                      as={TextField}
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* education */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>Education</Typography>
                    <Field
                      name="education"
                      as={TextField}
                      value={values.education}
                      onChange={(e) =>
                        setFieldValue("education", e.target.value)
                      }
                      error={touched.education && Boolean(errors.education)}
                      helperText={touched.education && errors.education}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* occupation */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>Occupation</Typography>
                    <Field
                      name="occupation"
                      as={TextField}
                      value={values.occupation}
                      onChange={(e) =>
                        setFieldValue("occupation", e.target.value)
                      }
                      error={touched.occupation && Boolean(errors.occupation)}
                      helperText={touched.occupation && errors.occupation}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* panNo */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      PAN No.<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="panNo"
                      as={TextField}
                      value={values.panNo}
                      onChange={(e) => setFieldValue("panNo", e.target.value)}
                      error={touched.panNo && Boolean(errors.panNo)}
                      helperText={touched.panNo && errors.panNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* Aadhar No. */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Aadhar No.<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="aadharNo"
                      as={TextField}
                      value={values.aadharNo}
                      onChange={(e) =>
                        setFieldValue("aadharNo", e.target.value)
                      }
                      error={touched.aadharNo && Boolean(errors.aadharNo)}
                      helperText={touched.aadharNo && errors.aadharNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* voterNo */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>Voter No.</Typography>
                    <Field
                      name="voterNo"
                      as={TextField}
                      value={values.voterNo}
                      onChange={(e) => setFieldValue("voterNo", e.target.value)}
                      error={touched.voterNo && Boolean(errors.voterNo)}
                      helperText={touched.voterNo && errors.voterNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* rationNo */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>Ration No.</Typography>
                    <Field
                      name="rationNo"
                      as={TextField}
                      value={values.rationNo}
                      onChange={(e) =>
                        setFieldValue("rationNo", e.target.value)
                      }
                      error={touched.rationNo && Boolean(errors.rationNo)}
                      helperText={touched.rationNo && errors.rationNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* dlNo */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>DL No.</Typography>
                    <Field
                      name="dlNo"
                      as={TextField}
                      value={values.dlNo}
                      onChange={(e) => setFieldValue("dlNo", e.target.value)}
                      error={touched.dlNo && Boolean(errors.dlNo)}
                      helperText={touched.dlNo && errors.dlNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* Nominee Name */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Nominee Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="nomineeName"
                      as={TextField}
                      value={values.nomineeName}
                      onChange={(e) =>
                        setFieldValue("nomineeName", e.target.value)
                      }
                      error={touched.nomineeName && Boolean(errors.nomineeName)}
                      helperText={touched.nomineeName && errors.nomineeName}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* Nominee Relation */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Nominee Relation<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="nomineeRelation"
                      as={TextField}
                      select
                      value={values.nomineeRelation}
                      onChange={(e) =>
                        setFieldValue("nomineeRelation", e.target.value)
                      }
                      error={
                        touched.nomineeRelation &&
                        Boolean(errors.nomineeRelation)
                      }
                      helperText={
                        touched.nomineeRelation && errors.nomineeRelation
                      }
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="BROTHER">Brother</MenuItem>
                      <MenuItem value="DAUGHTER">Daughter</MenuItem>
                      <MenuItem value="FATHER">Father</MenuItem>
                      <MenuItem value="HUSBAND">Husband</MenuItem>
                      <MenuItem value="MOTHER">Mother</MenuItem>
                      <MenuItem value="SISTER">Sister</MenuItem>
                      <MenuItem value="SON">Son</MenuItem>
                      <MenuItem value="WIFE">Wife</MenuItem>
                      <MenuItem value="DAUGHTER_IN_LAW">
                        Daughter in Law
                      </MenuItem>
                      <MenuItem value="BROTHER_IN_LAW">Brother in Law</MenuItem>
                      <MenuItem value="GRAND_DAUGHTER">Grand Daughter</MenuItem>
                      <MenuItem value="GRANDSON">Grand Son</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </Field>
                  </Box>
                </Grid>

                {/* Nominee Age */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Nominee Age<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="nomineeAge"
                      as={TextField}
                      type="number"
                      value={values.nomineeAge}
                      onChange={(e) =>
                        setFieldValue("nomineeAge", e.target.value)
                      }
                      error={touched.nomineeAge && Boolean(errors.nomineeAge)}
                      helperText={touched.nomineeAge && errors.nomineeAge}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* Nominee Mobile No. */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Nominee Mobile No.<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="nomineeMobileNo"
                      as={TextField}
                      value={values.nomineeMobileNo}
                      onChange={(e) =>
                        setFieldValue("nomineeMobileNo", e.target.value)
                      }
                      error={
                        touched.nomineeMobileNo &&
                        Boolean(errors.nomineeMobileNo)
                      }
                      helperText={
                        touched.nomineeMobileNo && errors.nomineeMobileNo
                      }
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* SMS Send Status */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      SMS Send Status
                    </Typography>
                    <Field
                      name="smsSend"
                      type="checkbox"
                      as={Switch}
                      checked={values.smsSend}
                      onChange={(e) =>
                        setFieldValue("smsSend", e.target.checked)
                      }
                      sx={{ transform: "scale(1.5)" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ ...styles.paper, mt: 2 }}>
              <Typography sx={styles.sectionHeader}>
                Introducer Details
              </Typography>
              <Grid container spacing={2}>
                {/* positionName */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Select Position<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="positionName"
                      as={TextField}
                      select
                      value={values.positionName}
                      onChange={(e) =>
                        setFieldValue("positionName", e.target.value)
                      }
                      error={
                        touched.positionName && Boolean(errors.positionName)
                      }
                      helperText={touched.positionName && errors.positionName}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {positions?.map((position) => (
                        <MenuItem
                          key={position.position}
                          value={position.positionName}
                        >
                          {`${position.positionNo} - ${position.positionName}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                </Grid>

                {/* introducerCode */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Introducer Code<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="introducerCode"
                      as={TextField}
                      value={values.introducerCode}
                      onChange={(e) => {
                        const code = e.target.value;
                        setFieldValue("introducerCode", code);

                        // Fetch introducer details when code length is sufficient
                        if (code.length >= 5) {
                          fetchIntroducerDetails(code, setFieldValue);
                        } else {
                          setFieldValue("introducerName", "");
                        }
                      }}
                      error={
                        touched.introducerCode && Boolean(errors.introducerCode)
                      }
                      helperText={
                        touched.introducerCode && errors.introducerCode
                      }
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* introducerName */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Introducer Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="introducerName"
                      as={TextField}
                      value={values.introducerName}
                      disabled
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Position<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="introPosition"
                      as={TextField}
                      value={values.introPosition}
                      disabled
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ ...styles.paper, mt: 2 }}>
              <Typography sx={styles.sectionHeader}>Payment Details</Typography>
              <Grid container spacing={2}>
                {/* Fees */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Fees (if any)
                    </Typography>
                    <Field
                      name="fees"
                      as={TextField}
                      type="number"
                      value={values.fees}
                      onChange={(e) => setFieldValue("fees", e.target.value)}
                      error={touched.fees && Boolean(errors.fees)}
                      helperText={touched.fees && errors.fees}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                {/* Payment By */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>
                      Payment By<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="paymentBy"
                      as={TextField}
                      select
                      value={values.paymentBy}
                      onChange={(e) =>
                        setFieldValue("paymentBy", e.target.value)
                      }
                      error={touched.paymentBy && Boolean(errors.paymentBy)}
                      helperText={touched.paymentBy && errors.paymentBy}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {paymentOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                </Grid>

                {/* Remarks */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ ...styles.label }}>Remarks</Typography>
                    <Field
                      name="remarks"
                      as={TextField}
                      value={values.remarks}
                      onChange={(e) => setFieldValue("remarks", e.target.value)}
                      error={touched.remarks && Boolean(errors.remarks)}
                      helperText={touched.remarks && errors.remarks}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button
                  type="submit"
                  sx={{ ...styles.button, ...styles.saveButton }}
                >
                  Save
                </Button>
                <Button
                  sx={{ ...styles.button, ...styles.newButton }}
                  onClick={() => resetForm()}
                >
                  New
                </Button>
              </Box>
            </Paper>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default AddCollector;

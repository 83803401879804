import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Switch,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
};

const NewInvestment = () => {
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [nomineeRelation, setNomineeRelation] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState("");
  const [schemes, setSchemes] = useState("");
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [schemeTypes, setSchemeTypes] = useState([]);
  const [selectedSchemeName, setSelectedSchemeName] = useState("");
  const [schemeTerm, setSchemeTerm] = useState("");
  const [schemeMode, setSchemeMode] = useState("");
  const [minimumAmount, setMinimumAmount] = useState("");
  const [filteredSchemes, setFilteredSchemes] = useState([]);
  const [misModeEnabled, setMisModeEnabled] = useState(false);
  const [misMode, setMisMode] = useState("N/A");
  const [depositAmount, setDepositAmount] = useState("");
  const [maturityAmount, setMaturityAmount] = useState("");
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [advisorCode, setAdvisorCode] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [selectedSchemeType, setSelectedSchemeType] = useState("");
  const [schemeId, setSchemeId] = useState("");
  const [policyAmount, setPolicyAmount] = useState("");
  const [policyDate, setPolicyDate] = useState("");
  const [maturityDate, setMaturityDate] = useState("");
  const [policyAmountError, setPolicyAmountError] = useState("");
  const [operationMode, setOperationMode] = useState("");
  const [remarks, setRemarks] = useState("");
  const [smsStatus, setSmsStatus] = useState(false);
  const [nomineeName, setNomineeName] = useState("");
  const [nomineeAge, setNomineeAge] = useState("");
  const [jointMemberCode, setJointMemberCode] = useState("");
  const [jointName, setJointName] = useState("");
  // const [nomineeRelation, setNomineeRelation] = useState("");

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const payload = {
    policyDate: policyDate,
    branchCode: selectedBranch,
    member_code: selectedMember?.memberCode,
    policyAmount: policyAmount,
    depositAmount: depositAmount,
    maturityAmount: maturityAmount,
    schemeType: selectedSchemeType,
    schemeName: selectedSchemeName,
    operationMode: operationMode,
    // jointMemberCode: jointMemberCode,
    // jointName: jointName,
    paymentBy: selectedPaymentOption,
    remarks: remarks,
    maturityDate: maturityDate,
    smsStatus: smsStatus,
    advisorCode: advisorCode,
    nomineeName: nomineeName,
    nomineeAge: nomineeAge,
    nomineeRelation: selectedRelation,
  };

  function resetFields() {
    setSelectedBranch("");
    setSelectedMember("");
    setPolicyAmount("");
    setMaturityAmount("");
    setSelectedSchemeType("");
    setSelectedSchemeName("");
    setOperationMode("");
    setSelectedPaymentOption("");
    setRemarks("");
    setMaturityDate("");
    setSmsStatus(false);
    setAdvisorCode("");
    setNomineeName("");
    setNomineeAge("");
    setSelectedRelation("");
    setSchemeTerm("");
    setSchemeMode("");
    setDepositAmount("");
    setAdvisorName("");
  }

  const handleSave = async () => {
    try {
      const response = await axiosInstance.post(
        "/admin/investment/createpno",
        payload
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Investment added successfully!",
        confirmButtonText: "OK",
      });
      resetFields();
    } catch (error) {
      console.error("Failed to add investment:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to add investment",
        confirmButtonText: "OK",
      });
    }
  };

  console.log("Payload: ", payload);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setPolicyDate(currentDate);
  }, []);

  useEffect(() => {
    if (policyDate && selectedSchemeType && schemeTerm) {
      calculateMaturityDate(policyDate, selectedSchemeType, schemeTerm);
    } else {
      setMaturityDate("");
    }
  }, [policyDate, selectedSchemeType, schemeTerm]);

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchMembers = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/approvedmembers");
      setMembers(response.data);
    } catch (error) {
      console.error("Error fetching members:", error.message);
    }
  }, []);

  const fetchNomineeRelation = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/nominee-relations");
      setNomineeRelation(response.data);
    } catch (error) {
      console.error("Error fetching nominee relation:", error.message);
    }
  }, []);

  const fetchPaymentOptions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/payment-by");
      setPaymentOptions(response.data || []);
    } catch (error) {
      console.error("Error fetching payment options:", error.message);
    }
  }, []);

  const handleMemberChange = (memberCode) => {
    const member = members.find((m) => m.memberCode === memberCode);
    setSelectedMember(member || null);
  };

  const fetchAdvisorDetails = async (code) => {
    try {
      const response = await axiosInstance.get(
        `/admin/Downline/details/${code}`
      );
      if (response.data) {
        setAdvisorName(response.data.advisorName);
      } else {
        setAdvisorName(""); // Reset name if no data found
        console.error("No data found for the given advisor code.");
      }
    } catch (error) {
      setAdvisorName(""); // Reset name in case of error
      console.error("Error fetching advisor details:", error.message);
    }
  };

  const handleAdvisorCodeChange = (e) => {
    const code = e.target.value;
    setAdvisorCode(code);
    if (code) {
      fetchAdvisorDetails(code);
    } else {
      setAdvisorName(""); // Clear name if code is empty
    }
  };

  const calculateMaturityDate = (policyDate, schemeType, term) => {
    const policyDateObj = new Date(policyDate);

    if (isNaN(policyDateObj.getTime())) {
      console.error("Invalid policy date.");
      return;
    }

    switch (schemeType) {
      case "DRD":
        policyDateObj.setDate(policyDateObj.getDate() + parseInt(term, 10));
        break;

      case "RD":
        policyDateObj.setMonth(policyDateObj.getMonth() + parseInt(term, 10));
        break;

      case "FD":
      case "MIS":
        policyDateObj.setMonth(policyDateObj.getMonth() + parseInt(term, 10));
        break;

      default:
        console.error("Unsupported scheme type:", schemeType);
        return;
    }

    const formattedDate = policyDateObj.toISOString().split("T")[0];
    // console.log("Calculated Maturity Date:", formattedDate);
    setMaturityDate(formattedDate);
  };

  const handlePolicyDateChange = (event) => {
    const date = event.target.value;
    setPolicyDate(date);
  };

  const fetchSchemes = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/investment/allcombined");
      setSchemes(response.data);
      const uniqueSchemeTypes = [
        ...new Set(response.data.map((scheme) => scheme.planCode)),
      ];
      setSchemeTypes(uniqueSchemeTypes);
    } catch (error) {
      console.error("Error fetching Schemes:", error.message);
    }
  }, []);

  const fetchMaturityAmount = useCallback(async () => {
    if (selectedSchemeType && schemeId && policyAmount) {
      try {
        const response = await axiosInstance.get(`/admin/investment/intrest`, {
          params: {
            schemeType: selectedSchemeType,
            schemeId: schemeId,
            amount: policyAmount,
          },
        });
        setMaturityAmount(parseFloat(response.data).toFixed(0));
      } catch (error) {
        console.error("Error fetching maturity amount:", error.message);
        setMaturityAmount("");
      }
    }
  }, [selectedSchemeType, schemeId, policyAmount]);

  useEffect(() => {
    // fetchMaturityAmount();
    fetchPaymentOptions();
    fetchNomineeRelation();
    fetchBranches();
    fetchMembers();
    fetchSchemes();
  }, [
    fetchSchemes,
    fetchMembers,
    fetchBranches,
    fetchNomineeRelation,
    fetchPaymentOptions,
    // fetchMaturityAmount,
  ]);
  useEffect(() => {
    fetchMaturityAmount();
  }, [fetchMaturityAmount]);

  const handlePolicyAmountChange = (event) => {
    const value = event.target.value;
    setPolicyAmount(value);
    if (value < minimumAmount) {
      setPolicyAmountError(`Policy Amount must be at least ${minimumAmount}`);
      setMaturityAmount("");
      setDepositAmount("");
    } else {
      setPolicyAmountError("");
      calculateDepositAmount(value, selectedSchemeType, schemeTerm);
    }
  };

  const calculateDepositAmount = (policyAmount, schemeType, schemeTerm) => {
    let depositAmount = 0;

    switch (schemeType) {
      case "DRD":
      case "RD":
        depositAmount = policyAmount * schemeTerm;
        break;
      case "FD":
      case "MIS":
        depositAmount = policyAmount;
        break;
      default:
        depositAmount = 0;
        break;
    }

    setDepositAmount(depositAmount);
  };

  const handleSchemeTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedSchemeType(selectedType);

    const filtered = schemes.filter(
      (scheme) => scheme.planCode === selectedType
    );
    setFilteredSchemes(filtered);
    setMisMode("N/A");
    setMisModeEnabled(selectedType === "MIS");
    setPolicyAmount("");
    setDepositAmount("");
    setSchemeTerm("");
    setSchemeMode("");
    setMaturityAmount("");
    setMaturityDate("");
  };

  const handleMisModeChange = (event) => {
    setMisMode(event.target.value);
  };

  const handleSchemeNameChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSchemeName(selectedName);

    const selectedScheme = filteredSchemes.find(
      (scheme) => scheme.planName === selectedName
    );

    if (selectedScheme) {
      setSchemeTerm(selectedScheme.term);
      setSchemeMode(selectedScheme.termMode);
      setSchemeId(selectedScheme.id);
      setMinimumAmount(selectedScheme.minimumAmount);

      calculateDepositAmount(
        policyAmount,
        selectedSchemeType,
        selectedScheme.term
      );
    }
  };

  return (
    <Box>
      <Typography sx={styles.header}>Add Investment</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Policy Details</Typography>

        <Grid container spacing={2}>
          {/* Policy Date */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Policy Date <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="date"
              value={policyDate}
              onChange={handlePolicyDateChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Branch Name */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Branch Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.branchCode}>
                  {`${branch.branchName} - ${branch.branchCode}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Select Member */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Select Member <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedMember?.memberCode || ""}
              onChange={(e) => handleMemberChange(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {members.map((member) => (
                <MenuItem key={member.id} value={member.memberCode}>
                  {`${member.memberName} - ${member.memberCode}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Member Name */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Member Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={selectedMember.memberName || ""}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            ></TextField>
          </Grid>

          {/* Mobile No. */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Mobile No. <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={selectedMember.mobileNo || ""}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            ></TextField>
          </Grid>

          {/* DOB */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography sx={styles.label}>
                  DOB <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  type="date"
                  disabled
                  value={selectedMember.dob || ""}
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={styles.label}>Age</Typography>
                <TextField
                  disabled
                  value={selectedMember.age || ""}
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Grid>
            </Box>
          </Grid>

          {/* Address */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Address <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={selectedMember.address || ""}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* District */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              District <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={selectedMember.district || ""}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* State */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              State <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={selectedMember.state || ""}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* Pin Code */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Pin Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={selectedMember.pincode || ""}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* Operation Mode */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Operation Mode <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={operationMode}
              onChange={(e) => setOperationMode(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              <MenuItem value="SINGLE">Single</MenuItem>
              <MenuItem value="JOINT">Joint</MenuItem>
            </TextField>
          </Grid>

          {/* Joint Mem.Code */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Joint Member Code</Typography>
            <TextField
              sx={styles.textField}
              value={jointMemberCode}
              onChange={(e) => setJointMemberCode(e.target.value)}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Joint Name */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Joint Name</Typography>
            <TextField
              value={jointName}
              onChange={(e) => setJointName(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Nominee Name */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Nominee Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={nomineeName}
              onChange={(e) => setNomineeName(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Nominee Age */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Nominee Age <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={nomineeAge}
              onChange={(e) => setNomineeAge(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Nominee Relation */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Nominee Relation <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedRelation}
              onChange={(e) => setSelectedRelation(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {nomineeRelation.map((relation, index) => (
                <MenuItem key={index} value={relation}>
                  {relation.replaceAll("_", " ")}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Scheme Type */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedSchemeType}
              onChange={handleSchemeTypeChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {schemeTypes.map((schemeType, index) => (
                <MenuItem key={index} value={schemeType}>
                  {schemeType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Scheme Name */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedSchemeName}
              onChange={handleSchemeNameChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              disabled={!selectedSchemeType}
            >
              {filteredSchemes.map((scheme, index) => (
                <MenuItem key={index} value={scheme.planName}>
                  {scheme.planName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Scheme Term */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Scheme Term</Typography>
            <TextField
              disabled
              sx={styles.textField}
              value={schemeTerm}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* Scheme Mode */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Mode <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={schemeMode}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            ></TextField>
          </Grid>

          {/* MIS Mode */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              MIS Mode<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={misMode}
              onChange={handleMisModeChange}
              disabled={!misModeEnabled}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: misModeEnabled ? "white" : "#eee",
                },
              }}
            >
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="half-yearly">Half-Yearly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>
          </Grid>

          {/* Policy Amount */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Policy Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="number"
              value={policyAmount}
              onChange={handlePolicyAmountChange}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
            {policyAmountError && (
              <Typography
                sx={{ color: "red", fontSize: "12px", marginTop: "4px" }}
              >
                {policyAmountError}
              </Typography>
            )}
          </Grid>

          {/* Deposit Amount */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Deposit Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={depositAmount}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* Maturiy Amount */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Maturiy Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={maturityAmount}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* Maturiy Date */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Maturiy Date<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="date"
              disabled
              value={maturityDate}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          {/* MIS Interest */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              MIS Interest <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Payment Details</Typography>
        <Grid container spacing={2}>
          {/* Payment By */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Payment By <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedPaymentOption}
              onChange={(e) => setSelectedPaymentOption(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {paymentOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Advisor/Collector */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Advisor/Collector Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={advisorCode}
              onChange={handleAdvisorCodeChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Remarks */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Remarks</Typography>
            <TextField
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Advisor Name */}
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Advisor Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={advisorName}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            ></TextField>
          </Grid>

          {/* SMS Send */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>SMS Send</Typography>
              <Switch
                checked={smsStatus}
                onChange={(e) => setSmsStatus(e.target.checked)}
                sx={{ transform: "scale(1.5)" }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={handleSave}
            sx={{ ...styles.button, ...styles.saveButton }}
          >
            Save
          </Button>
          <Button
            onClick={resetFields}
            sx={{ ...styles.button, ...styles.newButton }}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default NewInvestment;

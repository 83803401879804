import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const DrdDepositMaturity = ({
  minimumAmount,
  interestRate,
  term,
  compoundInterval,
  isFlexi,
  onCalculate,
}) => {
  const [totalDeposit, setTotalDeposit] = useState("0");
  const [maturityAmount, setMaturityAmount] = useState("0");

  useEffect(() => {
    if (minimumAmount && interestRate && term && compoundInterval) {
      calculateMaturityAndTotalDeposit();
    }
  }, [minimumAmount, interestRate, term, compoundInterval]);

  const calculateMaturityAndTotalDeposit = () => {
    const principal = parseFloat(minimumAmount) || 0;
    const rate = parseFloat(interestRate) || 0;
    const totalDays = parseInt(term, 10) || 0;

    // Input validation
    if (!isFlexi) {
      if (principal <= 0 || rate <= 0 || rate > 100 || totalDays <= 0) {
        alert("Invalid inputs. Check minimum amount, interest rate, and term.");
        return;
      }

      const totalDeposit = principal * totalDays;

      const dailyDeposits = new Array(totalDays).fill(principal);

      const maturityAmount = calculateMaturityAmount(
        dailyDeposits,
        rate,
        totalDays,
        compoundInterval,
        false
      );

      setTotalDeposit(totalDeposit.toFixed(2));
      setMaturityAmount(maturityAmount.toFixed(2));
      if (onCalculate) {
        onCalculate(totalDeposit.toFixed(2), maturityAmount.toFixed(2));
      }
    }
  };

  const calculateMaturityAmount = (
    dailyDeposits,
    rate,
    totalDays,
    interval,
    isFlexi
  ) => {
    if (!dailyDeposits || dailyDeposits.length === 0) {
      throw new Error("Daily deposits array cannot be null or empty.");
    }
    if (rate <= 0 || rate > 100) {
      throw new Error("Interest rate must be between 0 and 100.");
    }
    if (totalDays <= 0) {
      throw new Error("Total days must be a positive integer.");
    }

    let compoundFrequency;
    switch (interval.toLowerCase()) {
      case "daily":
        compoundFrequency = 365;
        break;
      case "monthly":
        compoundFrequency = 12;
        break;
      case "quarterly":
        compoundFrequency = 4;
        break;
      case "half-yearly":
        compoundFrequency = 2;
        break;
      case "yearly":
        compoundFrequency = 1;
        break;
      default:
        throw new Error("Invalid compound interval.");
    }

    let totalMaturityAmount = 0.0;

    for (let i = 0; i < dailyDeposits.length; i++) {
      const deposit = parseFloat(dailyDeposits[i]) || 0;

      if (deposit < 0) {
        throw new Error("Deposit amounts cannot be negative.");
      }

      const daysRemaining = totalDays - i;

      // Apply compound interest formula: A = P(1 + r/n)^(nt)
      const compoundInterest =
        Math.pow(
          1 + rate / (100 * compoundFrequency),
          compoundFrequency * (daysRemaining / 365)
        ) - 1;

      totalMaturityAmount += deposit + deposit * compoundInterest;
    }

    return totalMaturityAmount;
  };

  return (
    <></>
    // <div>
    //   <p>Total Deposit: {totalDeposit}</p>
    //   <p>Maturity Amount: {maturityAmount}</p>
    // </div>
  );
};

DrdDepositMaturity.propTypes = {
  minimumAmount: PropTypes.string.isRequired,
  interestRate: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  compoundInterval: PropTypes.string.isRequired,
  isFlexi: PropTypes.bool.isRequired,
  onCalculate: PropTypes.func,
};

export default DrdDepositMaturity;

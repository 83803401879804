const initialState = {
  token: null,
  username: null,
  usertype: null,
  roles: [],
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.payload.Token,
        username: action.payload.username,
        usertype: action.payload.usertype,
        roles: action.payload.roles,
        error: null,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    // case "LOGOUT":
    //   return {
    //     ...initialState,
    //   };
    default:
      return state;
  }
};

export default authReducer;

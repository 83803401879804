import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  Paper,
  useMediaQuery,
} from "@mui/material";
import LoginImage from "../Assets/Login/rb_7963.png";
// import LoginImage from "../Assets/Login/Login_image.svg";
import Logo from "../Assets/Login/vlLogo.png";
import indiaflag from "../Assets/Login/indiaflag.svg";
import lion from "../Assets/Login/lion.svg";
import Chatbot from "./GenericComponent/Chatboat";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/actions/authActions";

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const message = location.state?.message;

  useEffect(() => {
    if (message) {
      alert(message);
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    dispatch(loginUser(formData, navigate));
  };

  useEffect(() => {
    if (auth.error) {
      setError(auth.error.message || "Invalid username or password");
    }
    if (auth.token) {
      // navigate("/dashboard");
    }
  }, [auth, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box sx={{ background: "white" }}>
      <Grid container sx={{ height: "100vh" }}>
        {/* Left Side - Login Image */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            background: "#98b0ff",
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            display: { xs: "none", md: "block" }, // Hide on small screens
          }}
        ></Grid>

        {/* Right Side - Login Form */}

        <Grid
          item
          xs={12}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            px: 4,
            position: "relative", // Enable absolute positioning for children
          }}
        >
          {/* Indian Flag */}
          <Box
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
            }}
          >
            <img src={indiaflag} alt="Indian Flag" style={{ width: 50 }} />
          </Box>

          {/* Logo and Title */}
          <img
            src={Logo}
            alt="Logo"
            style={{ width: 70, marginBottom: 10, borderRadius: "50%" }}
          />
          <Typography
            sx={{
              fontSize: "26px",
              fontWeight: 700,
              mb: 1,
              color: "#027fff",
            }}
          >
            Sign In
          </Typography>
          <Typography
            // variant="h5"
            fontWeight="bold"
            mb={1}
            sx={{
              textTransform: "uppercase",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            VENKETLAXMI URBAN <br /> PUNE NIDHI LTD
          </Typography>
          <form onSubmit={handleSubmit}>
            {/* Form Fields */}
            <Box>
              {/* <TextField
                value={formData.username}
                onChange={handleChange}
                fullWidth
                label="User ID"
                variant="outlined"
                margin="normal"
                // required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              /> */}
              <TextField
                label="User ID"
                variant="outlined"
                placeholder="Enter User ID"
                name="username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    // height: "50px",
                    borderRadius: "8px",
                    // backgroundColor: "#EEEEEE",
                  },
                  mb: 2,
                }}
              />
              {/* <TextField
                value={formData.password}
                onChange={handleChange}
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                // required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              /> */}
              <TextField
                variant="outlined"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter Your Password"
                fullWidth
                required
                label="Password"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    // height: "50px",
                    // backgroundColor: "#EEEEEE",
                  },
                  mb: 1,
                }}
              />
              <Link
                href="#"
                underline="hover"
                display="block"
                textAlign="right"
                mb={2}
                color="#027fff"
                sx={{ fontWeight: "bold", fontFamily: "Poppins" }}
              >
                Forgot Password?
              </Link>
              {error && (
                <Typography
                  sx={{
                    color: "red",
                    mt: 0,
                    mb: 1,
                    textAlign: "center",
                  }}
                >
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                //   color="primary"
                size="large"
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  background: "#027fff",
                  ":hover": {
                    background: "#027fff",
                  },
                }}
              >
                Login Now
              </Button>
            </Box>
          </form>

          {/* Lion Image */}
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <img src={lion} alt="Lion" style={{ width: 60 }} />
          </Box>

          {/* Chatbot */}
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
            }}
          >
            <Chatbot />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;

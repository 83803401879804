import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Switch,
} from "@mui/material";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

function InvestmentRectification() {
  return (
    <Box>
      <Typography sx={styles.header}>Edit Investment</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Select by Policy No. <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={2.5}>
              <Button sx={{ ...styles.button, ...styles.saveButton }}>
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Policy Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Policy Date <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="date"
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Branch Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Select Member <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Operation Mode <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Member Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Joint Mem.Code</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display={"flex"} flexDirection={"row"} gap={2}>
              <Grid item xs={12} md={6}>
                <Typography sx={styles.label}>
                  DOB <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={styles.label}>Age</Typography>
                <TextField
                  sx={styles.textField}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Joint Name</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Relative Details</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Maturity Date <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="date"
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Mobile No <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Nominee Name</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Nominee Age</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Scheme Term</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Nominee Relation</Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Mode <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Address <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              MIS Mode <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              District <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Policy Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              State <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Deposit Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Pin Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Maturiy Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              MIS Interest <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Payment Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Payment By <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Advisor/Collector <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Remarks</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>SMS Send</Typography>
            <Switch sx={{ transform: "scale(1.5)" }} />
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
          <Button sx={{ ...styles.button, ...styles.saveButton }}>
            Update
          </Button>
          <Button sx={{ ...styles.button, ...styles.newButton }}>Print</Button>
          <Button sx={{ ...styles.button, ...styles.deleteButton }}>
            Delete
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default InvestmentRectification;

import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const InputField = ({
  label,
  type = "text",
  required = false,
  error,
  helperText,
  InputProps = {},
  ...props
}) => (
  <Grid item xs={12} md={6}>
    <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
    <TextField
      type={type}
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
          borderRadius: "10px",
          backgroundColor: InputProps?.style?.background || "#FFFFFF",
        },
      }}
      InputProps={{
        ...InputProps,
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  </Grid>
);

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "170px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

const FlexiRenewalPayment = () => {
  const [branches, setBranches] = useState([]);
  const [policyNo, setPolicyNo] = useState([]);
  const [selectedPolicyNo, setSelectedPolicyNo] = useState("");
  const [policyDetails, setPolicyDetails] = useState(null);
  console.log("policyDetails", policyDetails);

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchPolicyNo = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        "/admin/investment/flexy-policy-codes?schemeType=DRD"
      );
      setPolicyNo(response.data);
    } catch (error) {
      console.error("Error fetching policy no:", error.message);
    }
  }, []);

  const fetchPolicyDetails = async (policyCode) => {
    try {
      const response = await axiosInstance.get(
        `/admin/flexy/installments/${policyCode}`
      );
      setPolicyDetails(response.data);
    } catch (error) {
      setPolicyDetails(null);
      toast.error("Error fetching policy details.");
      console.error("Error fetching policy details:", error.message);
    }
  };

  useEffect(() => {
    fetchBranches();
    fetchPolicyNo();
  }, [fetchBranches, fetchPolicyNo]);

  const handleSave = async () => {
    // Validate form before proceeding
    const isValid = await formik.validateForm();
    if (!formik.isValid) {
      formik.setTouched(
        Object.keys(formik.initialValues).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        )
      );
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill all required fields correctly.",
      });
      return;
    }

    // Prepare the payload
    const payload = {
      txnDate: formik.values.txnDate || "",
      transactionFor: formik.values.transactionFor || "",
      remarks: formik.values.remarks || "",
      txnType: formik.values.txnType || "",
      amount: formik.values.amount || "",
      paymentBy: formik.values.paymentBy || "",
      addInvestment: {
        policyCode: selectedPolicyNo,
      },
      // branch: {
      //   branchCode: formik.values.branchCode || "",
      // },
      branch: {
        branchCode: policyDetails?.addInvestMent?.branch?.branchCode || "",
      },
    };

    // Make API call
    try {
      const response = await axiosInstance.post(
        "/admin/flexy/flexy-policy",
        payload
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Transaction saved successfully!",
        });
        formik.resetForm();
        setPolicyDetails(null);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save transaction.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the transaction.",
      });
      console.error("Save transaction error:", error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      branchCode: "",
      txnType: "",
      transactionFor: "",
      amount: "",
      remarks: "",
      paymentBy: "",
      txnDate: new Date().toISOString().split("T")[0],
      policyNo: "",
    },
    validationSchema: Yup.object({
      // branchCode: Yup.string().required("Branch Code is required"),
      txnType: Yup.string().required("Transaction Type is required"),
      amount: Yup.number()
        .required("Amount is required")
        .positive("Must be positive"),
      paymentBy: Yup.string().required("Payment method is required"),
      policyNo: Yup.string().required("Select Policy No."),
    }),
    onSubmit: handleSave,
  });

  return (
    <Box>
      <Typography sx={styles.header}>Flexi Deposit/Withdrawal</Typography>

      <Paper sx={styles.paper}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Policy Details
        </Typography>
        <Grid container spacing={2}>
          <InputField
            label="Txn Date"
            type="date"
            name="txnDate"
            value={formik.values.txnDate}
            onChange={formik.handleChange}
            required
            error={formik.touched.txnDate && Boolean(formik.errors.txnDate)}
            helperText={formik.touched.txnDate && formik.errors.txnDate}
          />

          <InputField
            label="Branch Name"
            // select
            required
            name="branchCode"
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
            // value={formik.values.branchCode}
            value={`${
              policyDetails?.addInvestMent?.branch.branchName || ""
            } - ${policyDetails?.addInvestMent?.branch.branchCode || ""}`}
            // onChange={formik.handleChange}
            // error={
            //   formik.touched.branchCode && Boolean(formik.errors.branchCode)
            // }
            // helperText={formik.touched.branchCode && formik.errors.branchCode}
          >
            {/* {branches.map((branch) => (
              <MenuItem key={branch.id} value={branch.branchCode}>
                {`${branch.branchName} - ${branch.branchCode}`}
              </MenuItem>
            ))} */}
          </InputField>

          <InputField
            label="Select Policy No."
            select
            name="policyNo"
            required
            value={formik.values.policyNo}
            onChange={(e) => {
              const selectedPolicy = e.target.value;
              formik.handleChange(e);
              setSelectedPolicyNo(selectedPolicy);
              fetchPolicyDetails(selectedPolicy);
            }}
            error={formik.touched.policyNo && Boolean(formik.errors.policyNo)}
            helperText={formik.touched.policyNo && formik.errors.policyNo}
          >
            {policyNo.map((policy, index) => (
              <MenuItem key={index} value={policy.policyCode}>
                {`${policy.applicantName} - ${policy.policyCode}`}
              </MenuItem>
            ))}
          </InputField>

          <InputField
            label="Member Code"
            value={policyDetails?.addInvestMent?.member?.memberCode || ""}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          ></InputField>
          <InputField
            label="Member Name"
            value={policyDetails?.addInvestMent?.member?.memberName || ""}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          ></InputField>
          <InputField
            label="Advisor/Collector Code"
            value={`${
              policyDetails?.addInvestMent?.addCollector?.advisorName || ""
            } - ${
              policyDetails?.addInvestMent?.addCollector?.advisorCode || ""
            }`}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          ></InputField>
          <InputField
            label="Mobile Number"
            value={policyDetails?.addInvestMent?.member?.mobileNo || ""}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          ></InputField>
          <InputField
            label="Plan Code"
            value={policyDetails?.addInvestMent?.schemeName || ""}
            required
            disabled
            InputProps={{
              style: { background: "#eee" },
            }}
          ></InputField>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Payment Details
        </Typography>
        <Grid container spacing={2}>
          <InputField
            label="Plan Balance"
            required
            disabled
            value={
              policyDetails?.planBalance !== undefined
                ? policyDetails.planBalance
                : ""
            }
            InputProps={{
              style: { background: "#eee" },
            }}
          />
          <InputField
            label="Txn. Type"
            select
            name="txnType"
            value={formik.values.txnType}
            onChange={formik.handleChange}
            required
            error={formik.touched.txnType && Boolean(formik.errors.txnType)}
            helperText={formik.touched.txnType && formik.errors.txnType}
          >
            <MenuItem value="Deposit">Deposit</MenuItem>
            <MenuItem value="Withdrawal">Withdrawal</MenuItem>
          </InputField>

          <InputField
            label="Transaction For"
            name="transactionFor"
            value={formik.values.transactionFor}
            onChange={formik.handleChange}
            error={
              formik.touched.transactionFor &&
              Boolean(formik.errors.transactionFor)
            }
            helperText={
              formik.touched.transactionFor && formik.errors.transactionFor
            }
          />

          <InputField
            label="Amount"
            name="amount"
            type="number"
            value={formik.values.amount}
            onChange={formik.handleChange}
            required
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />

          <InputField
            label="Remarks"
            name="remarks"
            value={formik.values.remarks}
            onChange={formik.handleChange}
            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
            helperText={formik.touched.remarks && formik.errors.remarks}
          />

          <InputField
            label="Payment By"
            select
            name="paymentBy"
            value={formik.values.paymentBy}
            onChange={formik.handleChange}
            required
            error={formik.touched.paymentBy && Boolean(formik.errors.paymentBy)}
            helperText={formik.touched.paymentBy && formik.errors.paymentBy}
          >
            <MenuItem value="CASH">CASH</MenuItem>
            <MenuItem value="CHEQUE">CHEQUE</MenuItem>
            <MenuItem value="ONLINE">ONLINE</MenuItem>
            <MenuItem value="NEFT">NEFT</MenuItem>
            <MenuItem value="SBACCOUNT">SBACCOUNT</MenuItem>
          </InputField>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={formik.handleSubmit}
            sx={{ ...styles.button, ...styles.saveButton, mt: 2.5 }}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default FlexiRenewalPayment;

import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
};

function AddDirector() {
  const [directors, setDirectors] = useState([]);
  const [branches, setBranches] = useState([]);
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [paymentOptions, setPaymentOptions] = useState([]);

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchPinCodeData = async (pinCode, setFieldValue) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pinCode}`
      );
      const data = response.data;

      if (data[0].Status === "Success") {
        const postOfficeData = data[0].PostOffice[0];
        setDistrict(postOfficeData.District);
        setState(postOfficeData.State);

        // Set Formik values
        setFieldValue("district", postOfficeData.District);
        setFieldValue("state", postOfficeData.State);
      } else {
        console.error("Error: Invalid Pin Code");
        setDistrict("");
        setState("");
        setFieldValue("district", "");
        setFieldValue("state", "");
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error.message);
    }
  };

  const fetchPaymentOptions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/payment-by");
      setPaymentOptions(response.data || []);
    } catch (error) {
      console.error("Error fetching payment options:", error.message);
    }
  }, []);

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchDirectors = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/directors");
      setDirectors(response.data);
    } catch (error) {
      console.error("Error fetching directors:", error.message);
      toast.warn("Error fetching directors data");
    }
  }, []);

  useEffect(() => {
    fetchDirectors();
    fetchBranches();
    fetchPaymentOptions();
  }, [fetchDirectors, fetchBranches, fetchPaymentOptions]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required."),
    full_name: Yup.string().required("Full name is required."),
    dob: Yup.date().required("Date of Birth is required."),
    din_or_promoter_No: Yup.string().optional(),
    appointment_date: Yup.date().required("Appointment Date is required."),
    reative_name: Yup.string().optional(),
    relative_relation: Yup.string().optional(),
    address: Yup.string().optional(),
    district: Yup.string().optional(),
    state: Yup.string().optional(),
    pinCode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid Pin Code")
      .required("Pin Code is required."),
    aadharNo: Yup.string()
      .matches(/^\d{12}$/, "Invalid Aadhar Number")
      .optional(),
    panNo: Yup.string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Number")
      .optional(),
    mobileNo: Yup.string()
      .matches(/^\d{10}$/, "Invalid Mobile Number")
      .required("Mobile Number is required."),
    emailId: Yup.string().email("Invalid Email Address").optional(),
    shareAmount: Yup.number()
      .positive("Share Amount must be positive")
      .required("Share Amount is required."),
    no_of_share: Yup.number()
      .integer("Number of Shares must be an integer")
      .optional(),
    paymentBy: Yup.string().required("Please select a payment method."),
    type: Yup.string().required("Type is required."),
    branchName: Yup.string().required("Branch Name is required."),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      title: values.title,
      full_name: values.full_name,
      dob: values.dob,
      din_or_promoter_No: values.din_or_promoter_No,
      appointment_date: values.appointment_date,
      reative_name: values.reative_name,
      relative_relation: values.relative_relation,
      address: values.address,
      district: values.district,
      state: values.state,
      pinCode: parseInt(values.pinCode),
      aadharNo: values.aadharNo,
      panNo: values.panNo,
      mobileNo: values.mobileNo,
      emailId: values.emailId,
      shareAmount: values.shareAmount,
      no_of_share: values.no_of_share,
      paymentBy: values.paymentBy,
      type: values.type,
      branchName: values.branchName,
    };

    try {
      const response = await axiosInstance.post(
        "/admin/directors/add",
        payload
      );
      if (response.status === 200) {
        Swal.fire("Success", "Directors added successfully!", "success");
        resetForm();
        setDistrict("");
        setState("");
        // setBranches("");
        fetchDirectors();
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error submitting the form:", error.message);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to add director/promotor.",
        "error"
      );
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          type: "",
          pinCode: "",
          branchName: "",
          district: "",
          state: "",
          title: "",
          full_name: "",
          state: "",
          aadharNo: "",
          dob: "",
          panNo: "",
          din_or_promoter_No: "",
          mobileNo: "",
          appointment_date: "",
          emailId: "",
          reative_name: "",
          relative_relation: "",
          shareAmount: "",
          address: "",
          no_of_share: "",
          paymentBy: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, resetForm }) => (
          <Form>
            <Box>
              <Typography sx={styles.header}>Add Director/Promoter</Typography>
              <Paper sx={styles.paper}>
                <Typography sx={styles.sectionHeader}>
                  Director/Promoter Details
                </Typography>
                <Grid container spacing={2}>
                  {/* Type  */}
                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Type <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="type"
                      value={values.type}
                      onChange={(e) => setFieldValue("type", e.target.value)}
                      error={touched.type && Boolean(errors.type)}
                      helperText={touched.type && errors.type}
                      as={TextField}
                      select
                      sx={styles.textField}
                      InputProps={{
                        style: { borderRadius: "10px" },
                      }}
                    >
                      <MenuItem value="DIRECTOR">Director</MenuItem>
                      <MenuItem value="PROMOTER">Promotor</MenuItem>
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Pin Code<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="pinCode"
                      as={TextField}
                      onBlur={(e) =>
                        fetchPinCodeData(e.target.value, setFieldValue)
                      }
                      error={touched.pinCode && Boolean(errors.pinCode)}
                      helperText={touched.pinCode && errors.pinCode}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  {/* Branch Name */}
                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Branch Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="branchName"
                      as={TextField}
                      select
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      value={undefined}
                      onChange={(e) =>
                        setFieldValue("branchName", e.target.value)
                      }
                      error={touched.branchName && Boolean(errors.branchName)}
                      helperText={touched.branchName && errors.branchName}
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.branchName}>
                          {`${branch.branchName} - ${branch.branchCode}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  {/* District */}
                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>District</Typography>
                    <Field
                      name="district"
                      as={TextField}
                      disabled
                      value={district}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Grid>

                  {/* Name */}
                  <Grid item xs={12} md={6}>
                    <Box display={"flex"} flexDirection={"row"} gap={2}>
                      <Grid item xs={12} md={3}>
                        <Typography sx={styles.label}>
                          Title <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Field
                          name="title"
                          as={TextField}
                          select
                          value={values.title}
                          onChange={(e) =>
                            setFieldValue("title", e.target.value)
                          }
                          error={touched.title && Boolean(errors.title)}
                          helperText={touched.title && errors.title}
                          sx={styles.textField}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <MenuItem value="MR">Mr.</MenuItem>
                          <MenuItem value="MS">Ms.</MenuItem>
                          <MenuItem value="MRS">Mrs.</MenuItem>
                          <MenuItem value="Smt">Smt</MenuItem>
                          <MenuItem value="MD">MD</MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Typography sx={styles.label}>
                          Full Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Field
                          name="full_name"
                          as={TextField}
                          value={values.full_name}
                          onChange={(e) =>
                            setFieldValue("full_name", e.target.value)
                          }
                          error={touched.full_name && Boolean(errors.full_name)}
                          helperText={touched.full_name && errors.full_name}
                          sx={styles.textField}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </Grid>
                    </Box>
                  </Grid>

                  {/* State */}
                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>State</Typography>
                    <Field
                      name="state"
                      as={TextField}
                      disabled
                      value={state}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Grid>

                  {/* Aadhar No */}
                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Aadhar No.<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="aadharNo"
                      as={TextField}
                      value={values.aadharNo}
                      onChange={(e) =>
                        setFieldValue("aadharNo", e.target.value)
                      }
                      error={touched.aadharNo && Boolean(errors.aadharNo)}
                      helperText={touched.aadharNo && errors.aadharNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      DOB<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="dob"
                      as={TextField}
                      type="date"
                      value={values.dob}
                      onChange={(e) => {
                        const dob = e.target.value;
                        setFieldValue("dob", dob);
                      }}
                      error={touched.dob && Boolean(errors.dob)}
                      helperText={touched.dob && errors.dob}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Pan No.<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="panNo"
                      as={TextField}
                      value={values.panNo}
                      onChange={(e) => setFieldValue("panNo", e.target.value)}
                      error={touched.panNo && Boolean(errors.panNo)}
                      helperText={touched.panNo && errors.panNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      DIN/Promoter No<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="din_or_promoter_No"
                      as={TextField}
                      value={values.din_or_promoter_No}
                      onChange={(e) =>
                        setFieldValue("din_or_promoter_No", e.target.value)
                      }
                      error={
                        touched.din_or_promoter_No &&
                        Boolean(errors.din_or_promoter_No)
                      }
                      helperText={
                        touched.din_or_promoter_No && errors.din_or_promoter_No
                      }
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="mobileNo"
                      as={TextField}
                      value={values.mobileNo}
                      onChange={(e) =>
                        setFieldValue("mobileNo", e.target.value)
                      }
                      error={touched.mobileNo && Boolean(errors.mobileNo)}
                      helperText={touched.mobileNo && errors.mobileNo}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Appointment Date <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="appointment_date"
                      as={TextField}
                      type="date"
                      value={values.appointment_date}
                      onChange={(e) =>
                        setFieldValue("appointment_date", e.target.value)
                      }
                      error={
                        touched.appointment_date &&
                        Boolean(errors.appointment_date)
                      }
                      helperText={
                        touched.appointment_date && errors.appointment_date
                      }
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Email ID <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="emailId"
                      as={TextField}
                      value={values.emailId}
                      onChange={(e) => setFieldValue("emailId", e.target.value)}
                      error={touched.emailId && Boolean(errors.emailId)}
                      helperText={touched.emailId && errors.emailId}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Relative Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="reative_name"
                      as={TextField}
                      value={values.reative_name}
                      onChange={(e) =>
                        setFieldValue("reative_name", e.target.value)
                      }
                      error={
                        touched.reative_name && Boolean(errors.reative_name)
                      }
                      helperText={touched.reative_name && errors.reative_name}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Face Value <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      disabled
                      as={TextField}
                      value="10"
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Relative Relation <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="relative_relation"
                      as={TextField}
                      select
                      value={values.relative_relation}
                      onChange={(e) =>
                        setFieldValue("relative_relation", e.target.value)
                      }
                      error={
                        touched.relative_relation &&
                        Boolean(errors.relative_relation)
                      }
                      helperText={
                        touched.relative_relation && errors.relative_relation
                      }
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="BROTHER">Brother</MenuItem>
                      <MenuItem value="DAUGHTER">Daughter</MenuItem>
                      <MenuItem value="FATHER">Father</MenuItem>
                      <MenuItem value="HUSBAND">Husband</MenuItem>
                      <MenuItem value="MOTHER">Mother</MenuItem>
                      <MenuItem value="SISTER">Sister</MenuItem>
                      <MenuItem value="SON">Son</MenuItem>
                      <MenuItem value="WIFE">Wife</MenuItem>
                      <MenuItem value="DAUGHTER_IN_LAW">
                        Daughter in Law
                      </MenuItem>
                      <MenuItem value="BROTHER_IN_LAW">Brother in Law</MenuItem>
                      <MenuItem value="GRAND_DAUGHTER">Grand Daughter</MenuItem>
                      <MenuItem value="GRANDSON">Grand Son</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Share Amount <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="shareAmount"
                      as={TextField}
                      type="number"
                      value={values.shareAmount}
                      onChange={(e) => {
                        const shareAmount = e.target.value;
                        const noOfShare = Math.floor(shareAmount / 10); // Calculate no of share
                        setFieldValue("shareAmount", shareAmount);
                        setFieldValue("no_of_share", noOfShare); // Set the calculated value
                      }}
                      error={touched.shareAmount && Boolean(errors.shareAmount)}
                      helperText={touched.shareAmount && errors.shareAmount}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      Address <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="address"
                      as={TextField}
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>
                      No. Of Share <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Field
                      name="no_of_share"
                      as={TextField}
                      value={values.no_of_share}
                      disabled
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{ ...styles.paper, mt: 2 }}>
                <Typography sx={styles.sectionHeader}>
                  Payment Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography sx={styles.label}>Payment By</Typography>
                    <Field
                      name="paymentBy"
                      as={TextField}
                      select
                      value={values.paymentBy}
                      onChange={(e) =>
                        setFieldValue("paymentBy", e.target.value)
                      }
                      error={touched.paymentBy && Boolean(errors.paymentBy)}
                      helperText={touched.paymentBy && errors.paymentBy}
                      sx={styles.textField}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {paymentOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box display={"flex"} gap={2} mt={2.5}>
                      <Button
                        type="submit"
                        sx={{
                          ...styles.button,
                          ...styles.saveButton,
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          setDistrict("");
                          setState("");
                          // setBranches("");
                        }}
                        sx={{
                          ...styles.button,
                          ...styles.newButton,
                        }}
                      >
                        New
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Form>
        )}
      </Formik>
      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>
          Director/Promoter Data List
        </Typography>
        <Box sx={{ overflowX: "auto", mt: 2 }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px 20px 0px 0px",
              width: { md: "100%", xs: "250px" },
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  {[
                    "S No.",
                    "Director ID",
                    "Full Name",
                    "DIN No.",
                    "AppDate",
                    "Share Amount",
                    "No. Of Share",
                    "Payment By",
                    // "Action",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        color: "white",
                        fontWeight: 400,
                        fontSize: { md: "13px", xs: "10px" },
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {directors.map((record, index) => (
                  <TableRow key={record.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{record.director_id}</TableCell>
                    <TableCell>{record.full_name}</TableCell>
                    <TableCell>{record.din_or_promoter_No}</TableCell>
                    <TableCell>
                      {new Date(record.appointment_date).toLocaleDateString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </TableCell>
                    <TableCell>{record.shareAmount}</TableCell>
                    <TableCell>{record.no_of_share}</TableCell>
                    <TableCell>{record.paymentBy}</TableCell>
                    {/* <TableCell>
                      {
                        <IconButton>
                          <VisibilityIcon />
                        </IconButton>
                      }
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
}

export default AddDirector;

import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function AddFYMaster() {
  const token = localStorage.getItem("token");
  const [fyData, setFyData] = useState([]);
  const [fyName, setFyName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fetchFYData = async () => {
    try {
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/fy-master/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data?.status === "success") {
        setFyData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching FY Master data:", error);
    }
  };

  useEffect(() => {
    fetchFYData();
  }, []);

  const handleAddFYMaster = async () => {
    if (!fyName || !fromDate || !toDate) {
      // Swal.fire("Error", "All fields are required!", "error");
      alert("All fields are required!");
      return;
    }

    try {
      const payload = { fyName, fromDate, toDate };
      const response = await axios.post(
        "https://api.vlnidhi.com/api/admin/fy-master/add",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire("Success", "FY Master added successfully!", "success");
      setFyName("");
      setFromDate("");
      setToDate("");
      fetchFYData();
    } catch (error) {
      console.error("Error adding FY Master:", error);
      Swal.fire("Error", "Failed to add FY Master!", "error");
    }
  };

  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    sectionHeader: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    label: {
      fontFamily: "Poppins, sans-serif",
      fontSize: { xs: "12px", md: "14px" },
    },
    textField: {
      width: "100%",
      borderColor: "#D9D9D9CC",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
    },
    button: {
      borderRadius: "10px",
      width: { xs: "100%", md: "128px" },
      height: { xs: "38px", md: "48px" },
      color: "white",
      fontWeight: "bold",
    },
    saveButton: {
      background: "#DFBF41",
      ":hover": {
        background: "#a18e46",
      },
    },
    cancelButton: {
      background: "#d73925",
      ":hover": {
        background: "#a1392c",
      },
    },
  };
  return (
    <Box>
      <Typography sx={styles.header}>Add FY Master</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Entry Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>
              FY Name : <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              value={fyName}
              onChange={(e) => setFyName(e.target.value)}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>From Date :</Typography>
            <TextField
              sx={styles.textField}
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>To Date :</Typography>
            <TextField
              sx={styles.textField}
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <Button
              onClick={handleAddFYMaster}
              sx={{ ...styles.button, ...styles.saveButton, mt: 2.5 }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ overflowX: "auto", mt: 2, p: 1 }}>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "20px 20px 0px 0px",
            width: { md: "100%", xs: "250px" },
            // maxWidth: "1210px",
            overflowX: "auto",
          }}
        >
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#413AF1" }}>
              <TableRow>
                {["Sl No", "FY Name", "From Date", "To Date"].map(
                  (header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        color: "white",
                        fontWeight: 400,
                        fontSize: { md: "13px", xs: "10px" },
                      }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {fyData.map((record, index) => (
                <TableRow key={record.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{record.fyName}</TableCell>
                  <TableCell>
                    {new Date(record.fromDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(record.toDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default AddFYMaster;

import {
  Typography,
  Box,
  Paper,
  Grid,
  FormControl,
  TextField,
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  height: "48px",
  borderRadius: "12px",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "&.MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  width: "100%",
  fontSize: { xs: "12px", md: "14px" },
  mt: -1,
};

const validationSchema = Yup.object({
  userId: Yup.string().required("User ID is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  fullName: Yup.string().required("Full name is required"),
  email: Yup.string().required("Email is required"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(10, "Password must be at least 10 characters"),
  branch: Yup.string().required("Branch is required"),
  backDate: Yup.string().required("Date is required"),
  rePrint: Yup.string().required("Select option"),
});

const UserCreation = () => {
  const formik = useFormik({
    initialValues: {
      userId: "",
      password: "",
      fullName: "",
      email: "",
      mobileNumber: "",
      branch: "",
      backDate: "",
      rePrint: "",
      deleteAccess: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form values:", values);
    },
  });

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        User Create
      </Typography>
      <Paper sx={{ borderRadius: "20px", p: "2%" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          User Information
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  User ID *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="userId"
                    name="userId"
                    value={formik.values.userId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.userId && Boolean(formik.errors.userId)
                    }
                    helperText={formik.touched.userId && formik.errors.userId}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Password *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="password"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Full Name *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="fullName"
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Email ID *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Mobile Number *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="mobileNumber"
                    name="mobileNumber"
                    type="number"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobileNumber &&
                      Boolean(formik.errors.mobileNumber)
                    }
                    helperText={
                      formik.touched.mobileNumber && formik.errors.mobileNumber
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Log In Branch *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="branch"
                    name="branch"
                    type="select"
                    value={formik.values.branch}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.branch && Boolean(formik.errors.branch)
                    }
                    helperText={formik.touched.branch && formik.errors.branch}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Back Date *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="backDate"
                    name="backDate"
                    type="Date"
                    value={formik.values.backDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.backDate && Boolean(formik.errors.backDate)
                    }
                    helperText={
                      formik.touched.backDate && formik.errors.backDate
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Re-Print *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="rePrint"
                    name="rePrint"
                    type="select"
                    value={formik.values.branch}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.branch && Boolean(formik.errors.branch)
                    }
                    helperText={formik.touched.branch && formik.errors.branch}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Delete Access *
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      ...textFieldStyle,
                    }}
                    id="deleteAccess"
                    name="deleteAccess"
                    type="select"
                    value={formik.values.deleteAccess}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.deleteAccess &&
                      Boolean(formik.errors.deleteAccess)
                    }
                    helperText={
                      formik.touched.deleteAccess && formik.errors.deleteAccess
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    User Status * 
                  </Typography>
                  <FormControl fullWidth>
                    <Switch
                      id="enableFeature"
                      name="enableFeature"
                      checked={formik.values.enableFeature || false}
                      onChange={(e) =>
                        formik.setFieldValue("enableFeature", e.target.checked)
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={5} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              Color="#DFBF41"
              type="submit"
              sx={{ textTransform: "none", borderRadius: "10px" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
      <Box
        sx={{
          padding: 2,
          marginTop: 4,
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Branch Access List
        </Typography>

        {/* Table */}
        <Paper
          sx={{
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2E3BFF" }}>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  B.CODE
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  B.NAME
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <Checkbox sx={{ color: "#ffffff" }} />
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>{/* Add rows here dynamically if needed */}</TableBody>
          </Table>
        </Paper>
      </Box>

      <Box
        sx={{
          padding: 2,
          marginTop: 4,
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Plan Access
        </Typography>

        {/* Table */}
        <Paper
          sx={{
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2E3BFF" }}>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Plan Code
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  TABLE
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Term
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <Checkbox sx={{ color: "#ffffff" }} />
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>{/* Add rows here dynamically if needed */}</TableBody>
          </Table>
        </Paper>
      </Box>

      <Box
        sx={{
          padding: 2,
          marginTop: 4,
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          Loan Access
        </Typography>

        {/* Table */}
        <Paper
          sx={{
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2E3BFF" }}>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Loan Code
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  LoanName
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Term
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <Checkbox sx={{ color: "#ffffff" }} />
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>{/* Add rows here dynamically if needed */}</TableBody>
          </Table>
        </Paper>
      </Box>

      <Box
        sx={{
          padding: 2,
          marginTop: 4,
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
          }}
        >
          User Data List
        </Typography>

        {/* Table */}
        <Paper
          sx={{
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2E3BFF" }}>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  User ID
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  User Type
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Login Branch
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Full Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Mobile No
                </TableCell>
                <TableCell
                  sx={{
                    color: "#ffffff",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <Checkbox sx={{ color: "#ffffff" }} />
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>{/* Add rows here dynamically if needed */}</TableBody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
};

export default UserCreation;

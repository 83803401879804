import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  MenuItem,
  Switch,
  FormControlLabel,
  Button,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import bitcoin from "../../Assets/bitcoin.png";

const MyForm = () => {
  // const { handleSubmit, control, reset } = useForm();
  // const [menuItems, setMenuItems] = useState([]);

  // useEffect(() => {
  //   // Fetch MenuItem options from API
  //   fetch("https://api.example.com/menu-options")
  //     .then((response) => response.json())
  //     .then((data) => setMenuItems(data))
  //     .catch((error) => console.error("Error fetching menu items:", error));
  // }, []);

  // const onSubmit = (data) => {
  //   console.log("Form Data:", data);
  // };

  return (
    // <form onSubmit={handleSubmit(onSubmit)}>
    //   <Paper sx={{p: 2}}>
    //     <Grid container spacing={2}>
    //       {/* Text Fields */}
    //       {Array.from({ length: 10 }, (_, i) => (
    //         <Grid item xs={12} md={6} key={i}>
    //           <Controller
    //             name={`textField${i + 1}`}
    //             control={control}
    //             defaultValue=""
    //             render={({ field }) => (
    //               <TextField
    //                 {...field}
    //                 label={`TextField ${i + 1}`}
    //                 variant="outlined"
    //                 fullWidth
    //               />
    //             )}
    //           />
    //         </Grid>
    //       ))}

    //       {/* Select Field */}
    //       <Grid item xs={12} md={6}>
    //         <Controller
    //           name="selectField"
    //           control={control}
    //           defaultValue=""
    //           render={({ field }) => (
    //             <TextField
    //               {...field}
    //               select
    //               label="Select Field"
    //               variant="outlined"
    //               fullWidth
    //             >
    //               {menuItems.map((item) => (
    //                 <MenuItem key={item.id} value={item.value}>
    //                   {item.label}
    //                 </MenuItem>
    //               ))}
    //             </TextField>
    //           )}
    //         />
    //       </Grid>

    //       {/* Date Field */}
    //       <Grid item xs={12} md={6}>
    //         <Controller
    //           name="dateField"
    //           control={control}
    //           defaultValue=""
    //           render={({ field }) => (
    //             <TextField
    //               {...field}
    //               label="Date Field"
    //               type="date"
    //               InputLabelProps={{ shrink: true }}
    //               variant="outlined"
    //               fullWidth
    //             />
    //           )}
    //         />
    //       </Grid>

    //       {/* Switch */}
    //       <Grid item xs={12}>
    //         <Controller
    //           name="switchField"
    //           control={control}
    //           defaultValue={false}
    //           render={({ field }) => (
    //             <FormControlLabel
    //               control={<Switch {...field} checked={field.value} />}
    //               label="Switch Field"
    //             />
    //           )}
    //         />
    //       </Grid>

    //       {/* Submit and Reset Buttons */}
    //       <Grid item xs={12}>
    //         <Button variant="contained" color="primary" type="submit">
    //           Submit
    //         </Button>
    //         <Button
    //           variant="outlined"
    //           color="secondary"
    //           onClick={() => reset()}
    //           style={{ marginLeft: "10px" }}
    //         >
    //           Reset
    //         </Button>
    //       </Grid>
    //     </Grid>
    //   </Paper>
    // </form>
    <>
      {/* <img src={bitcoin} alt="bitcoin" style={{width: "200px", }} /> */}
      <Box
        component="img"
        src={bitcoin}
        alt="bitcoin"
        sx={{
          width: "500px",
          transition: "transform 0.6s ease-in-out",
          "&:hover": {
            animation: "spin 0.6s linear infinite",
          },
          "@keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" },
          },
        }}
      />
    </>
  );
};

export default MyForm;

import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Switch,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "128px" },
    height: { xs: "38px", md: "48px" },
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
  },
  saveButton: {
    background: "#DFBF41",
    ":hover": {
      background: "#a18e46",
    },
  },
};

function UserMenuAccess() {
  const [widgets, setWidgets] = useState([]);
  const [menus, setMenus] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchWidgets = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/dashboard");
      setWidgets(response.data);
    } catch (error) {
      console.error("Error fetching widgets:", error.message);
    }
  }, []);

  const fetchMenus = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        "/admin/user_access/with-subsections"
      );
      setMenus(response.data);
    } catch (error) {
      console.error("Error fetching menus:", error.message);
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/usernames");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchWidgets();
    fetchMenus();
    fetchUsers();
  }, [fetchWidgets, fetchMenus, fetchUsers]);

  const handleParentChange = (section, isChecked) => {
    setMenus((prevMenus) =>
      prevMenus.map((menu) => {
        if (menu[section]) {
          return {
            [section]: menu[section].map((item) => ({
              ...item,
              selected: isChecked,
            })),
          };
        }
        return menu;
      })
    );
  };

  const handleChildChange = (section, id, isChecked) => {
    setMenus((prevMenus) =>
      prevMenus.map((menu) => {
        if (menu[section]) {
          return {
            [section]: menu[section].map((item) =>
              item.id === id ? { ...item, selected: isChecked } : item
            ),
          };
        }
        return menu;
      })
    );
  };

  return (
    <Box>
      <Typography sx={styles.header}>User Menu Access</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Select User ID</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                User ID <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {users.map((user, index) => (
                  <MenuItem key={index} value={user}>
                    {user}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button sx={{ ...styles.button, ...styles.saveButton, mt: 2.5 }}>
              Go
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>
          Dashboard Widgets Details
        </Typography>
        <Grid container spacing={4}>
          {widgets?.map((widget) => (
            <Grid item xs={12} md={2} key={widget.id}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={styles.label}>
                  {widget.field_name} <span style={{ color: "red" }}>*</span>
                </Typography>
                <Switch sx={{ transform: "scale(1.5)" }} />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={{ ...styles.button, ...styles.saveButton }}>
            Set Access
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Select Menu List</Typography>
        {menus?.map((menu, index) => {
          const section = Object.keys(menu)[0];
          const children = menu[section];
          const allSelected = children.every((item) => item.selected);
          // console.log("allSelected", allSelected);
          const someSelected = children.some((item) => item.selected);
          // console.log("someSelected", someSelected);

          return (
            <Box key={index} sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={allSelected}
                    indeterminate={!allSelected && someSelected}
                    onChange={(e) =>
                      handleParentChange(section, e.target.checked)
                    }
                  />
                }
                label={section}
              />
              <Box sx={{ ml: 4, display: "flex", flexDirection: "column" }}>
                {children.map((child) => (
                  <FormControlLabel
                    key={child.id}
                    control={
                      <Checkbox
                        color="primary"
                        checked={child.selected || false}
                        onChange={(e) =>
                          handleChildChange(section, child.id, e.target.checked)
                        }
                      />
                    }
                    label={child.name}
                  />
                ))}
              </Box>
            </Box>
          );
        })}
      </Paper>
    </Box>
  );
}

export default UserMenuAccess;

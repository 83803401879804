import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Box,
  Switch,
  styled,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const AddSbAccount = () => {
  const token = localStorage.getItem("token");
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  // const [branches, setBranches] = useState([]);
  const [plans, setPlans] = useState([]);

  const [formData, setFormData] = useState({
    openingDate: "",
    nomineeName: "",
    nomineeAge: "",
    nomineeRelation: "",
    modeOfOperation: "",
    branchCode: "",
    selectPlan: "",
    openingAmount: "",
    accountStatus: "",
    smsSend: "",
    debitCardIssue: "",
    name: "",
    memberCode: "",
  });

  const [paymentBy, setPaymentBy] = React.useState("");
  // const [accountStatus, setAccountStatus] = React.useState(false);
  // const [smsSend, setSmsSend] = React.useState(false);
  // const [debitCardIssue, setDebitCardIssue] = React.useState(false);

  // Fetch members from API
  useEffect(() => {
    const fetchMembers = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          "https://api.vlnidhi.com/api/admin/approvedmembers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setMembers(data);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  // Inside your component
  const handleAdvisorCodeBlur = async () => {
    const advisorCode = formik.values.collectorCode;
    const token = localStorage.getItem("token");

    if (advisorCode && token) {
      try {
        const response = await axios.get(
          `https://api.vlnidhi.com/api/admin/Downline/details/${advisorCode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.advisorName) {
          formik.setFieldValue("advisorName", response.data.advisorName);
          formik.setFieldError("collectorCode", ""); // Clear any previous errors
        } else {
          formik.setFieldValue("advisorName", ""); // Clear advisorName
          alert("Advisor Code not found.");
          formik.setFieldError("collectorCode", "Advisor Code not found");
        }
      } catch (error) {
        console.error("Error fetching advisor details:", error);
        formik.setFieldValue("advisorName", ""); // Clear advisorName on error
        alert("Advisor Code not found.");
        formik.setFieldError("collectorCode", "Advisor Code not found");
      }
    } else {
      formik.setFieldValue("advisorName", ""); // Clear advisorName if advisorCode is empty
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePaymentByChange = (event) => {
    setPaymentBy(event.target.value);
  };

  const handleToggleChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  useEffect(() => {
    // Fetch plans
    axios
      .get(
        "https://api.vlnidhi.com/api/admin/savings-account-schemes/get-all-saving-account-schemes",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  const handleMemberSelect = (event) => {
    const selectedMemberCode = event.target.value;
    const selected = members.find(
      (member) => member.memberCode === selectedMemberCode
    );

    if (selected) {
      formik.setValues({
        ...formik.values,
        memberCode: selected.memberCode || "",
        openingDate: selected.registrationDate || "",
        memberName: selected.memberName || "",
        dob: selected.dob || "",
        age: selected.age || "",
        mobileNo: selected.mobileNo || "",
        address: selected.address || "",
        district: selected.district || "",
        state: selected.state || "",
        pinCode: selected.pincode || "",
      });
    }

    setSelectedMember(selected);
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    memberCode: Yup.string().required("Select a member first"),
    branchCode: Yup.string().required("Select branch"),
    modeOfOperation: Yup.string().required("Select Mode Of Operation"),
    selectPlan: Yup.string().required("Select plan"),
    openingAmount: Yup.string().required("Opening amount is required"),
    // collectorCode: Yup.string().required("Advisor Code is required"),
    paymentBy: Yup.string().required("Payment method is required"),
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      memberCode: "",
      branchCode: "",
      modeOfOperation: "",
      selectPlan: "",
      openingAmount: "",
      accountStatus: false,
      smsSend: false,
      debitCardIssue: false,
      openingDate: "",
      nomineeName: "",
      nomineeAge: "",
      nomineeRelation: "",
      collectorCode: "",
      paymentBy: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const token = localStorage.getItem("token");

      // Map schemeName to selectPlan
      // const formData = {
      //   ...values,
      //   selectPlan: values.schemeName, // Copy schemeName to selectPlan
      // };

      try {
        const response = await fetch(
          "https://api.vlnidhi.com/api/admin/saving-accounts/create-account",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values),
          }
        );

        if (response.ok) {
          const result = await response.json();
          Swal.fire({
            icon: "success",
            title: "Saving Account Created Successfully",
            text: `Account Number: ${result.accountNumber}`,
          });
          resetForm();
        } else {
          const errorResult = await response.json();
          console.error("Failed to create account:", response.statusText);
          Swal.fire({
            icon: "error",
            title: "Failed to create account.",
            text: `${errorResult.message || response.statusText}`,
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An unexpected error occurred. Please try again later.",
        });
      }
    },
  });

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    // opacity: 0.6,
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
    // mb: 1,
    mt: -1,
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
          }}
        >
          Add SB Account
        </Typography>
        <Paper sx={{ borderRadius: "20px", p: "2%" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            Saving Details
          </Typography>

          <Grid container spacing={2}>
            {/* Select by Member * */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Select By Member<span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  name="memberCode"
                  value={formik.values.memberCode}
                  onChange={(e) => {
                    handleMemberSelect(e);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.memberCode &&
                    Boolean(formik.errors.memberCode)
                  }
                  helperText={
                    formik.touched.memberCode && formik.errors.memberCode
                  }
                  sx={{ ...textFieldStyle }}
                  InputProps={{ style: { borderRadius: "10px" } }}
                >
                  {members.map((member) => (
                    <MenuItem key={member.id} value={member.memberCode}>
                      {member.memberName} - {member.memberCode}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            {/* Opening Date* */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Opening Date
              </Typography>
              <TextField
                fullWidth
                disabled
                type="date"
                value={formik.values.openingDate || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {/* Member Name* */}
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Member Name
              </Typography>
              <TextField
                fullWidth
                disabled
                // value={formData.memberName}
                value={formik.values.memberName || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>

            {/* DOB* */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                DOB
              </Typography>
              <TextField
                type="date"
                fullWidth
                disabled
                // value={formData.dob}
                value={formik.values.dob || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>

            {/* Age */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Age
              </Typography>
              <TextField
                fullWidth
                disabled
                // value={formData.age}
                value={formik.values.age || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {/* Mobile No* */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Mobile No
              </Typography>
              <TextField
                fullWidth
                disabled
                // value={formData.mobileNo}
                value={formik.values.mobileNo || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>

            {/* Nominee Name */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Nominee Name
              </Typography>
              <TextField
                fullWidth
                name="nomineeName"
                value={formik.values.nomineeName || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            {/* Nominee Age */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Nominee Age
              </Typography>
              <TextField
                fullWidth
                name="nomineeAge"
                value={formik.values.nomineeAge || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Nominee Relation */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Nominee Relation
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  name="nomineeRelation"
                  value={formik.values.nomineeRelation || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem value="FATHER">FATHER</MenuItem>
                  <MenuItem value="MOTHER">MOTHER</MenuItem>
                  <MenuItem value="BROTHER">BROTHER</MenuItem>
                  <MenuItem value="SISTER">SISTER</MenuItem>
                  <MenuItem value="HUSBAND">HUSBAND</MenuItem>
                  <MenuItem value="WIFE">WIFE</MenuItem>
                  <MenuItem value="SON">SON</MenuItem>
                  <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
                  <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
                  <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
                  <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
                  <MenuItem value="GRANDSON">GRANDSON</MenuItem>
                  <MenuItem value="OTHER">OTHER</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            {/* Address* */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Address
              </Typography>
              <TextField
                fullWidth
                disabled
                value={formik.values.address || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>

            {/* District* */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                District
              </Typography>
              <TextField
                fullWidth
                disabled
                value={formik.values.district || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            {/* State* */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                State
              </Typography>
              <TextField
                fullWidth
                disabled
                value={formik.values.state || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>
            {/* Pin Code* */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Pin Code
              </Typography>
              <TextField
                fullWidth
                disabled
                value={formik.values.pinCode || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            {/* Branch Name * */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Branch Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  name="branchCode"
                  value={formik.values.branchCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.branchCode &&
                    Boolean(formik.errors.branchCode)
                  }
                  helperText={
                    formik.touched.branchCode && formik.errors.branchCode
                  }
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem value="001">MAIN OFFICE - 001</MenuItem>
                  <MenuItem value="002">BANER OFFICE - 002</MenuItem>
                  <MenuItem value="003">BHAVDHAN OFFICE - 003</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            {/* Mode Of Operation */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Mode Of Operation<span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl
                fullWidth
                // name="modeOfOperation"
                // value={formData.modeOfOperation}
                // onChange={handleInputChange}
              >
                <TextField
                  select
                  name="modeOfOperation"
                  value={formik.values.modeOfOperation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.modeOfOperation &&
                    Boolean(formik.errors.modeOfOperation)
                  }
                  helperText={
                    formik.touched.modeOfOperation &&
                    formik.errors.modeOfOperation
                  }
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem value="Single">Single</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {/* Select Plan */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Select Plan<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="selectPlan"
                value={formik.values.selectPlan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.selectPlan && Boolean(formik.errors.selectPlan)
                }
                helperText={
                  formik.touched.selectPlan && formik.errors.selectPlan
                }
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {plans.map((plan) => (
                  <MenuItem key={plan.id} value={plan.schemeName}>
                    {`${plan.schemeName}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Opening Amount */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Opening Amount<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                name="openingAmount"
                value={formik.values.openingAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.openingAmount &&
                  Boolean(formik.errors.openingAmount)
                }
                helperText={
                  formik.touched.openingAmount && formik.errors.openingAmount
                }
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {/* Advisor */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Advisor Code
              </Typography>
              <TextField
                fullWidth
                name="collectorCode"
                value={formik.values.collectorCode}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e); // Call formik.handleBlur with the event
                  handleAdvisorCodeBlur();
                }}
                error={
                  formik.touched.collectorCode &&
                  Boolean(formik.errors.collectorCode)
                }
                helperText={
                  formik.touched.collectorCode && formik.errors.collectorCode
                } // Display error message
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* Opening Fees(If any) */}
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Opening Fees(If any)
              </Typography>
              <TextField
                fullWidth
                name="openingfees"
                value={formik.values.openingfees || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {/* Advisor Name */}
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...fontStyle }}>Advisor Name</Typography>
              <TextField
                disabled
                name="advisorName"
                value={formik.values.advisorName || ""}
                onChange={formik.handleChange}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            {/* Applicant Photo */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="column"
              alignItems="center"
            >
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Applicant Photo
              </Typography>
              <ImageUploadWrapper>
                <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
              </ImageUploadWrapper>
              <UploadButton variant="contained" component="label">
                Choose File
                <input type="file" hidden />
              </UploadButton>
              <Caption>Photo Size: Maximum 10MB</Caption>
            </Grid>

            {/* Signature */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              container
              direction="column"
              alignItems="c"
            >
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Signature
              </Typography>
              <ImageUploadWrapper>
                {/* Removed PersonIcon for Signature */}
              </ImageUploadWrapper>
              <UploadButton variant="contained" component="label">
                Choose File
                <input type="file" hidden />
              </UploadButton>
              <Caption>Photo Size: Maximum 10MB</Caption>
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ marginTop: "2%", p: "2%", borderRadius: "20px" }}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 1,
              }}
            >
              Payment Details
            </Typography>
            <Grid container spacing={2}>
              {/* Payment By* */}
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Payment By<span style={{ color: "red" }}>*</span>
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    select
                    name="paymentBy"
                    value={formik.values.paymentBy || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paymentBy &&
                      Boolean(formik.errors.paymentBy)
                    }
                    helperText={
                      formik.touched.paymentBy && formik.errors.paymentBy
                    }
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="CASH">CASH</MenuItem>  
                    <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                    <MenuItem value="ONLINE">ONLINE</MenuItem>
                    <MenuItem value="NEFT">NEFT</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              {/* Remarks */}
              <Grid item xs={12} md={6} sx={{ mt: {} }}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Remarks
                </Typography>
                <TextField
                  name="remarks"
                  value={formik.values.remarks || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} mt={1}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Account Status<span style={{ color: "red" }}>*</span>
                </Typography>
                <Switch
                  name="accountStatus"
                  checked={formik.values.accountStatus}
                  onChange={formik.handleChange}
                  sx={{ transform: "scale(1.5)" }}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} mt={1}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  SMS Send<span style={{ color: "red" }}>*</span>
                </Typography>
                <Switch
                  name="smsSend"
                  checked={formik.values.smsSend}
                  onChange={formik.handleChange}
                  sx={{ transform: "scale(1.5)" }}
                />
              </Grid> */}
              {/* Debit Card Issue* */}
              {/* <Grid item xs={12} sm={4} mt={1}>
                <Typography
                  sx={{
                    ...fontStyle,
                  }}
                >
                  Debit Card Issue<span style={{ color: "red" }}>*</span>
                </Typography>
                <Switch
                  name="debitCardIssue"
                  checked={formik.values.debitCardIssue}
                  onChange={formik.handleChange}
                  sx={{ transform: "scale(1.5)" }}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: { xs: "center", sm: "right" }, // Optional: Aligns text differently based on screen size
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end", // Aligns the buttons to the right
                  flexWrap: "wrap",
                }}
              >
                {/* <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#E8C30B",
                    color: "white",
                    borderRadius: "10px",
                    marginRight: { xs: "0", md: "10px" },
                    width: { xs: "100%", md: "247px" },
                    marginBottom: { xs: "10px", sm: "0" },
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    ":hover": { background: "#a58f1f" },
                  }}
                >
                  New
                </Button> */}

                <Button
                  variant="contained"
                  type="submit"
                  // onClick={handleFormSubmit}
                  sx={{
                    backgroundColor: "#3029D9",
                    color: "white",
                    borderRadius: "10px",
                    width: { xs: "100%", md: "247px" },
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    ":hover": { background: "#2f2aa9" },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default AddSbAccount;

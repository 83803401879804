import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Switch,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const InputField = ({
  label,
  type = "text",
  required = false,
  error,
  helperText,
  ...props
}) => (
  <Grid item xs={12} md={6}>
    <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
    <TextField
      type={type}
      sx={{
        width: "100%",
        borderColor: "#D9D9D9CC",
        backgroundColor: "#FFFFFF",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
        },
      }}
      InputProps={{
        style: { borderRadius: "10px" },
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  </Grid>
);

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

function EditAdvisorCollector() {
  const [advisors, setAdvisors] = useState([]);
  const [selectedAdvisorCode, setSelectedAdvisorCode] = useState("");
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [branches, setBranches] = useState([]);
  const [positions, setPositions] = useState([]);

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchAdvisors = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/collector/allApprove");
      setAdvisors(response.data);
    } catch (error) {
      console.error("Error fetching advisors:", error.message);
    }
  }, []);

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchPositions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/positions/all");
      setPositions(response.data);
    } catch (error) {
      console.error("Error fetching positions:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchAdvisors();
    fetchBranches();
    fetchPositions();
  }, [fetchAdvisors, fetchBranches, fetchPositions]);

  const fetchIntroducerDetails = async (code, setFieldValue) => {
    try {
      const response = await axiosInstance.get(
        `/admin/Downline/details/${code}`
      );
      setFieldValue("introPosition", response.data.position || "");
      setFieldValue("introducerName", response.data.advisorName || "");
    } catch (error) {
      console.error("Error fetching introducer details:", error.message);
      setFieldValue("introducerName", "");
      setFieldValue("introPosition", "");
    }
  };

  const handleSelectAdvisor = (event) => {
    const selectedCode = event.target.value;
    setSelectedAdvisorCode(selectedCode);

    const selected = advisors.find(
      (advisor) => advisor.advisorCode === selectedCode
    );
    setSelectedAdvisor(selected);
  };

  const formik = useFormik({
    initialValues: {
      joiningDate: selectedAdvisor?.joiningDate || "",
      branchCode: selectedAdvisor?.branchCode || "",
      advisorName: selectedAdvisor?.advisorName || "",
      positionName: selectedAdvisor?.positionName || "",
      introducerCode: selectedAdvisor?.introducerCode || "",
      introducerName: selectedAdvisor?.introducerName || "",
      address: selectedAdvisor?.address || "",
      district: selectedAdvisor?.district || "",
      state: selectedAdvisor?.state || "",
      pinCode: selectedAdvisor?.pinCode || "",
      education: selectedAdvisor?.education || "",
      occupation: selectedAdvisor?.occupation || "",
      dob: selectedAdvisor?.dob || "",
      age: selectedAdvisor?.age || "",
      nomineeName: selectedAdvisor?.nomineeName || "",
      nomineeRelation: selectedAdvisor?.nomineeRelation || "",
      nomineeAge: selectedAdvisor?.nomineeAge || "",
      mobileNo: selectedAdvisor?.mobileNo || "",
      fees: selectedAdvisor?.fees || "",
      paymentBy: selectedAdvisor?.paymentBy || "",
      remarks: selectedAdvisor?.remarks || "",
      smsSend: selectedAdvisor?.smsSend || false,
    },

    validationSchema: Yup.object({}),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!selectedAdvisor) {
        Swal.fire({
          icon: "error",
          title: "No Advisor Selected",
          text: "Please select a advisor/collector to update!",
        });
        return;
      }

      try {
        const response = await axiosInstance.put(
          `/admin/updateByAdvisorCode/${selectedAdvisor.advisorCode}`,
          values
        );
        Swal.fire({
          icon: "success",
          title: "Update Successful",
          text: `Advisor/Collector ${selectedAdvisor.advisorName} has been updated successfully!`,
        });
        fetchAdvisors();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: "Something went wrong while updating the Advisor/Collector. Please try again.",
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Typography sx={styles.header}>Edit Advisor/Collector</Typography>
        <Paper sx={styles.paper}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Search Details
          </Typography>
          <Grid container spacing={2}>
            <InputField
              value={selectedAdvisorCode}
              onChange={handleSelectAdvisor}
              label="Select by Code"
              select
              required
            >
              {advisors.map((advisor) => (
                <MenuItem key={advisors.id} value={advisor.advisorCode}>
                  {`${advisor.advisorName} (${advisor.advisorCode})`}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
        </Paper>

        <Paper sx={{ ...styles.paper, mt: 2 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Advisor/Collector Details
          </Typography>
          <Grid container spacing={2}>
            <InputField
              label="Joining Date"
              type="date"
              value={formik.values.joiningDate}
              onChange={formik.handleChange}
              error={formik.touched.joiningDate && !!formik.errors.joiningDate}
              helperText={
                formik.touched.joiningDate && formik.errors.joiningDate
              }
              name="joiningDate"
              required
            />
            <InputField
              label="Branch Name"
              value={formik.values.branchCode}
              onChange={formik.handleChange}
              error={formik.touched.branchCode && !!formik.errors.branchCode}
              helperText={formik.touched.branchCode && formik.errors.branchCode}
              name="branchCode"
              select
              required
            >
              {branches.map((branch) => (
                <MenuItem key={branch?.id} value={branch?.branchCode}>
                  {`${branch?.branchName} - ${branch?.branchCode}`}
                </MenuItem>
              ))}
            </InputField>
            <InputField
              label="Member Name"
              value={formik.values.advisorName}
              onChange={(e) => {
                const upperCaseValue = e.target.value.toUpperCase();
                formik.setFieldValue("advisorName", upperCaseValue);
              }}
              name="advisorName"
              error={formik.touched.advisorName && !!formik.errors.advisorName}
              helperText={
                formik.touched.advisorName && formik.errors.advisorName
              }
              required
            />
            <InputField
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && !!formik.errors.address}
              helperText={formik.touched.address && formik.errors.address}
              name="address"
              required
            />
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <InputField
                  label="DOB"
                  type="date"
                  value={formik.values.dob}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const birthDate = new Date(e.target.value);
                    const today = new Date();
                    const calculatedAge =
                      today.getFullYear() - birthDate.getFullYear();
                    formik.setFieldValue("age", calculatedAge);
                  }}
                  name="dob"
                  error={formik.touched.dob && !!formik.errors.dob}
                  helperText={formik.touched.dob && formik.errors.dob}
                  required
                />
                <InputField
                  label="Age"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  error={formik.touched.age && !!formik.errors.age}
                  helperText={formik.touched.age && formik.errors.age}
                  name="age"
                  disabled
                />
              </Box>
            </Grid>
            <InputField
              label="District"
              value={formik.values.district}
              onChange={formik.handleChange}
              error={formik.touched.district && !!formik.errors.district}
              helperText={formik.touched.district && formik.errors.district}
              name="district"
              required
            />
            <InputField
              label="Nominee Name"
              value={formik.values.nomineeName}
              onChange={formik.handleChange}
              error={formik.touched.nomineeName && !!formik.errors.nomineeName}
              helperText={
                formik.touched.nomineeName && formik.errors.nomineeName
              }
              name="nomineeName"
              required
            />
            <InputField
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && !!formik.errors.state}
              helperText={formik.touched.state && formik.errors.state}
              name="state"
              required
            />
            <InputField
              value={formik.values.nomineeRelation}
              onChange={formik.handleChange}
              error={
                formik.touched.nomineeRelation &&
                !!formik.errors.nomineeRelation
              }
              helperText={
                formik.touched.nomineeRelation && formik.errors.nomineeRelation
              }
              name="nomineeRelation"
              label="Nominee Relation"
              select
            >
              <MenuItem value="FATHER">FATHER</MenuItem>
              <MenuItem value="MOTHER">MOTHER</MenuItem>
              <MenuItem value="BROTHER">BROTHER</MenuItem>
              <MenuItem value="SISTER">SISTER</MenuItem>
              <MenuItem value="HUSBAND">HUSBAND</MenuItem>
              <MenuItem value="WIFE">WIFE</MenuItem>
              <MenuItem value="SON">SON</MenuItem>
              <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
              <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
              <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
              <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
              <MenuItem value="GRANDSON">GRANDSON</MenuItem>
              <MenuItem value="OTHER">OTHER</MenuItem>
            </InputField>
            <InputField
              value={formik.values.nomineeAge}
              onChange={formik.handleChange}
              error={formik.touched.nomineeAge && !!formik.errors.nomineeAge}
              helperText={formik.touched.nomineeAge && formik.errors.nomineeAge}
              name="nomineeAge"
              label="Nominee Age"
            />
            <InputField
              label="Pin Code"
              value={formik.values.pinCode}
              onChange={formik.handleChange}
              error={formik.touched.pinCode && !!formik.errors.pinCode}
              helperText={formik.touched.pinCode && formik.errors.pinCode}
              name="pinCode"
              required
            />
            <InputField
              label="Mobile No."
              value={formik.values.mobileNo}
              onChange={formik.handleChange}
              error={formik.touched.mobileNo && !!formik.errors.mobileNo}
              helperText={formik.touched.mobileNo && formik.errors.mobileNo}
              name="mobileNo"
              required
            />
            <InputField
              label="Occupation"
              value={formik.values.occupation}
              onChange={formik.handleChange}
              error={formik.touched.occupation && !!formik.errors.occupation}
              helperText={formik.touched.occupation && formik.errors.occupation}
              name="occupation"
            />
            <InputField
              label="Education"
              value={formik.values.education}
              onChange={formik.handleChange}
              error={formik.touched.education && !!formik.errors.education}
              helperText={formik.touched.education && formik.errors.education}
              name="education"
            />
          </Grid>
        </Paper>

        <Paper sx={{ ...styles.paper, mt: 2 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Introducer Details
          </Typography>
          <Grid container spacing={2}>
            <InputField
              value={formik.values.positionName}
              onChange={formik.handleChange}
              error={
                formik.touched.positionName && !!formik.errors.positionName
              }
              helperText={
                formik.touched.positionName && formik.errors.positionName
              }
              name="positionName"
              label="Select Position"
              select
              required
            >
              {positions.map((position) => (
                <MenuItem
                  key={position?.position}
                  value={position?.positionName || ""}
                >
                  {`${position?.positionName} - ${position?.position}`}
                </MenuItem>
              ))}
            </InputField>
            <InputField
              label="Introducer Name"
              value={formik.values.introducerName}
              name="introducerName"
              disabled
            />
            <InputField
              label="Introducer Code"
              value={formik.values.introducerCode}
              onChange={(e) => {
                const code = e.target.value;
                formik.setFieldValue("introducerCode", code);
                if (code.length >= 11) {
                  fetchIntroducerDetails(code, formik.setFieldValue);
                } else {
                  formik.setFieldValue("introducerName", "");
                  formik.setFieldValue("introPosition", "");
                }
              }}
              error={
                formik.touched.introducerCode && !!formik.errors.introducerCode
              }
              helperText={
                formik.touched.introducerCode && formik.errors.introducerCode
              }
              name="introducerCode"
              required
            />
            <InputField
              label="Position"
              value={formik.values.introPosition}
              name="introPosition"
              disabled
              required
            />
          </Grid>
        </Paper>

        <Paper sx={{ ...styles.paper, mt: 2 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Payment Details
          </Typography>
          <Grid container spacing={2}>
            <InputField
              label="Fees (if any)"
              value={formik.values.fees}
              error={formik.touched.fees && !!formik.errors.fees}
              helperText={formik.touched.fees && formik.errors.fees}
              name="fees"
              required
            />
            <InputField
              value={formik.values.paymentBy}
              onChange={formik.handleChange}
              error={formik.touched.paymentBy && !!formik.errors.paymentBy}
              helperText={formik.touched.paymentBy && formik.errors.paymentBy}
              name="paymentBy"
              label="Payment By"
              select
              required
            >
              <MenuItem value="CASH">CASH</MenuItem>
              <MenuItem value="CHEQUE">CHEQUE</MenuItem>
              <MenuItem value="ONLINE">ONLINE</MenuItem>
              <MenuItem value="NEFT">NEFT</MenuItem>
            </InputField>
            <InputField
              value={formik.values.remarks}
              onChange={formik.handleChange}
              error={formik.touched.remarks && !!formik.errors.remarks}
              helperText={formik.touched.remarks && formik.errors.remarks}
              name="remarks"
              label="Remarks"
            />
            <Grid item xs={12} md={3}>
              <Typography sx={styles.label}>SMS Send</Typography>
              <Switch
                sx={{ transform: "scale(1.5)" }}
                checked={formik.values.smsSend}
                onChange={(_, checked) =>
                  formik.setFieldValue("smsSend", checked)
                }
              />
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={1}>
            <Button
              type="submit"
              sx={{ ...styles.button, ...styles.saveButton }}
            >
              Update
            </Button>
          </Box>
        </Paper>
      </Box>
    </form>
  );
}

export default EditAdvisorCollector;

import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "128px" },
    height: { xs: "38px", md: "48px" },
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
  },
  saveButton: {
    background: "#DFBF41",
    ":hover": {
      background: "#a18e46",
    },
  },
  deleteButton: {
    background: "#d73925",
    ":hover": {
      background: "#a1392c",
    },
  },
  newButton: {
    background: "#05ACD6",
    ":hover": {
      background: "#228097",
    },
  },
};



function ShareTransfer() {
  const [members, setMembers] = useState([]);
  const [promotors, setPromotors] = useState([]);
  const [selectedPromotor, setSelectedPromotor] = useState(null);
  const [paymentBy, setPaymentBy] = useState([]);
  const [selectedMember, setSelectedMember] = useState(""); // Stores selected member ID
  const [memberDetails, setMemberDetails] = useState(null); // Stores details of selected member
  const [transferAmount, setTransferAmount] = useState("");
  const [noOfShares, setNoOfShares] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [remarks, setRemarks] = useState("");

  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchMembers = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/approvedmembers");
      setMembers(response.data);
    } catch (error) {
      console.error("Error fetching members:", error.message);
    }
  }, []);

  const fetchPromotors = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/promotors");
      setPromotors(response.data);
    } catch (error) {
      console.error("Error fetching promotors:", error.message);
    }
  }, []);

  const fetchPaymentBy = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/payment-by");
      setPaymentBy(response.data);
    } catch (error) {
      console.error("Error fetching paymentBy:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchMembers();
    fetchPromotors();
    fetchPaymentBy();
  }, [fetchMembers, fetchPromotors, fetchPaymentBy]);

  const handlePromotorChange = (event) => {
    const promotorCode = event.target.value;
    const promotor = promotors.find((item) => item.memberCode === promotorCode);
    setSelectedPromotor(promotor);
  };

  const handleTransferAmountChange = (event) => {
    const amount = event.target.value;
    setTransferAmount(amount);

    // Calculate No. of Share based on Transfer Amount
    const shares = amount ? Math.floor(amount / 10) : "";
    setNoOfShares(shares);
  };

  const handleSave = async () => {
    if (
      !selectedPromotor ||
      !selectedMember ||
      !transferAmount ||
      !paymentMethod
    ) {
      // alert("Please fill in all required fields.");
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in all required fields.",
      });
      return;
    }

    const payload = {
      fromMember: selectedPromotor.memberCode,
      toMember: memberDetails?.memberCode,
      transferAmount: parseFloat(transferAmount),
      transferDate: new Date().toISOString(),
      paymentMethod: paymentMethod,
      branchId: memberDetails?.branch?.id,
      remarks: remarks,
    };

    try {
      const response = await axiosInstance.post(
        "/admin/share/transfer",
        payload
      );
      Swal.fire({
        icon: "success",
        title: "Transfer Successful",
        text: "Share transfer has been completed successfully!",
      });
      resetFields();
      fetchMembers();
      fetchPromotors();
    } catch (error) {
      console.error("Error transferring shares:", error);
      Swal.fire({
        icon: "error",
        title: "Transfer Failed",
        text: "An error occurred during the share transfer. Please try again.",
      });
    }
  };

  const resetFields = () => {
    setSelectedPromotor(null);
    setSelectedMember("");
    setMemberDetails(null);
    setTransferAmount("");
    setNoOfShares("");
    setPaymentMethod("");
    setRemarks("");
  };

  return (
    <Box>
      <Typography sx={styles.header}>Share Transfer</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Share Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Search by Code <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                value={selectedMember} // Holds the selected member ID
                onChange={(e) => {
                  const selected = members.find(
                    (member) => member.id === parseInt(e.target.value, 10)
                  );
                  setSelectedMember(e.target.value); // Update selected member ID
                  setMemberDetails(selected); // Update member details
                }}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {members.map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.memberName} - {member.memberCode}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Branch <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                value={`${memberDetails?.branch?.branchName || ""}  ${
                  memberDetails?.branch?.branchCode || ""
                }`}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Member Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                value={memberDetails?.memberName || ""}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Transfer Date <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                type="date"
                value={new Date().toISOString().split("T")[0]}
                disabled
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>Date Of Join</Typography>
              <TextField
                type="date"
                disabled
                value={memberDetails?.registrationDate || ""}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Share Alloted From <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                value={selectedPromotor?.memberCode || ""}
                onChange={handlePromotorChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {promotors.map((promotor) => (
                  <MenuItem key={promotor.id} value={promotor.memberCode}>
                    {`${promotor.memberName} - ${promotor.memberCode}`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>Previous Balance</Typography>
              <TextField
                disabled
                value={memberDetails?.shareAmount || ""}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>Share Balance</Typography>
              <TextField
                disabled
                value={selectedPromotor?.shareAmount || ""}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>Previous No Of Share</Typography>
              <TextField
                disabled
                value={
                  memberDetails?.shareAmount
                    ? Math.floor(memberDetails.shareAmount / 10)
                    : ""
                }
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Transfer Amount <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                type="number"
                value={transferAmount}
                onChange={handleTransferAmountChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>Face Value</Typography>
              <TextField
                disabled
                value={10}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                No. of Share <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                value={noOfShares}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Payment Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>
                Payment By <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {paymentBy.map((method, index) => (
                  <MenuItem key={index} value={method}>
                    {method}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={styles.label}>Remarks</Typography>
              <TextField
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
        >
          <Button
            onClick={handleSave}
            sx={{ ...styles.button, ...styles.saveButton }}
          >
            Save
          </Button>
          <Button
            onClick={resetFields}
            sx={{ ...styles.button, ...styles.newButton }}
          >
            Clear
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ShareTransfer;

import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const InputField = ({
  label,
  type = "text",
  required = false,
  error,
  helperText,
  ...props
}) => (
  <Grid item xs={12} md={6}>
    <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
    <TextField
      type={type}
      sx={{
        width: "100%",
        borderColor: "#D9D9D9CC",
        backgroundColor: "#FFFFFF",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
        },
      }}
      InputProps={{
        style: { borderRadius: "10px" },
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  </Grid>
);

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

function MemberRectification() {
  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const [members, setMembers] = useState([]);
  const [selectedMemberCode, setSelectedMemberCode] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [branches, setBranches] = useState([]);
  const [promotors, setPromotors] = useState([]);

  const fetchMembers = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/approvedmembers");
      setMembers(response.data);
    } catch (error) {
      console.error("Error fetching members:", error.message);
    }
  }, []);

  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  const fetchPromotors = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/promotors");
      setPromotors(response.data);
    } catch (error) {
      console.error("Error fetching promotors:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchMembers();
    fetchBranches();
    fetchPromotors();
  }, [fetchMembers, fetchBranches, fetchPromotors]);

  const formik = useFormik({
    initialValues: {
      registrationDate: selectedMember?.registrationDate || "",
      pincode: selectedMember?.pincode || "",
      memberNameTitle: selectedMember?.memberNameTitle || "",
      memberName: selectedMember?.memberName || "",
      aadharNo: selectedMember?.aadharNo || "",
      panNo: selectedMember?.panNo || "",
      voterNo: selectedMember?.voterNo || "",
      mobileNo: selectedMember?.mobileNo || "",
      dob: selectedMember?.dob || "",
      age: selectedMember?.age || "",
      emailId: selectedMember?.emailId || "",
      address: selectedMember?.address || "",
      district: selectedMember?.district || "",
      state: selectedMember?.state || "",
      branchName: selectedMember?.branchName || "",

      // sponsorName: selectedMember?.sponsorName || "",
      introMemCode: selectedMember?.introMemCode || "",
      martialStatus: selectedMember?.martialStatus || "",
      paymentBy: selectedMember?.paymentBy || "",
      gender: selectedMember?.gender || "",
      nomineeName: selectedMember?.nomineeName || "",
      nomineemobileNo: selectedMember?.nomineemobileNo || "",
      nomineeRelation: selectedMember?.nomineeRelation || "",
      nomineeAge: selectedMember?.nomineeAge || "",
      occupation: selectedMember?.occupation || "",
      education: selectedMember?.education || "",
      memberfees: selectedMember?.memberfees || 0.0,
      noOfShare: selectedMember?.noOfShare || 0,
      shareAmount: selectedMember?.shareAmount || 0.0,
      remarks: selectedMember?.remarks || "",
      promotorCode: selectedMember?.promotorCode || "",
    },

    validationSchema: Yup.object({
      registrationDate: Yup.date().required("Registration Date is required"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be 6 digits")
        .required("Pincode is required"),
      memberNameTitle: Yup.string().required("Title is required"),
      memberName: Yup.string().required("Member Name is required"),
      aadharNo: Yup.string()
        .matches(/^\d{12}$/, "Aadhar No. must be 12 digits")
        .required("Aadhar No. is required"),
      panNo: Yup.string().matches(
        /^[A-Z]{5}\d{4}[A-Z]{1}$/,
        "Invalid PAN format"
      ),
      voterNo: Yup.string(),
      mobileNo: Yup.string()
        .matches(/^\d{10}$/, "Mobile No. must be 10 digits")
        .required("Mobile No. is required"),
      dob: Yup.date().required("Date of Birth is required"),
      emailId: Yup.string().email("Invalid Email format"),
      address: Yup.string().required("Address is required"),
      district: Yup.string().required("District is required"),
      state: Yup.string().required("State is required"),
      branchName: Yup.string().required("Branch Name is required"),
      paymentBy: Yup.string().required("Payment method is required"),
      nomineeName: Yup.string().required("Nominee Name is required"),
      nomineemobileNo: Yup.string().matches(
        /^\d{10}$/,
        "Mobile No. must be 10 digits"
      ),
      noOfShare: Yup.number()
        .required("No of Share is required")
        .positive("No of Share must be greater than zero")
        .integer("No of Share must be an integer"),
      promotorCode: Yup.string().required("Promotor is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!selectedMember) {
        Swal.fire({
          icon: "error",
          title: "No Member Selected",
          text: "Please select a member to update!",
        });
        return;
      }
      // Update `shareAmount` based on `noOfShare`
      values.shareAmount = values.noOfShare * 10;

      try {
        const response = await axiosInstance.put(
          `/admin/updatemember/${selectedMember.memberCode}`,
          values
        );
        Swal.fire({
          icon: "success",
          title: "Update Successful",
          text: `Member ${selectedMember.memberName} has been updated successfully!`,
        });
        fetchMembers(); // Refresh the list after update
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Update Failed",
          text: "Something went wrong while updating the member. Please try again.",
        });
      }
    },
  });

  const handleSelectMember = (event) => {
    const selectedCode = event.target.value;
    setSelectedMemberCode(selectedCode);

    const selected = members.find(
      (member) => member.memberCode === selectedCode
    );
    setSelectedMember(selected);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Typography sx={styles.header}>Edit Member</Typography>

        <Paper sx={styles.paper}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Search Details
          </Typography>
          <Grid container spacing={2}>
            <InputField
              value={selectedMemberCode}
              onChange={handleSelectMember}
              label="Select by Code"
              select
              required
            >
              {members.map((member) => (
                <MenuItem key={member.id} value={member.memberCode}>
                  {`${member.memberName} (${member.memberCode})`}
                </MenuItem>
              ))}
            </InputField>
          </Grid>
        </Paper>
        <>
          <Paper sx={{ ...styles.paper, mt: 2 }}>
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Member Details
            </Typography>
            <Grid container spacing={2}>
              <InputField
                label="Registration Date"
                type="date"
                value={formik.values.registrationDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.registrationDate &&
                  !!formik.errors.registrationDate
                }
                helperText={
                  formik.touched.registrationDate &&
                  formik.errors.registrationDate
                }
                name="registrationDate"
                required
              />
              <InputField
                label="Pin Code"
                value={formik.values.pincode}
                onChange={formik.handleChange}
                error={formik.touched.pincode && !!formik.errors.pincode}
                helperText={formik.touched.pincode && formik.errors.pincode}
                name="pincode"
                required
              />
              <Grid item xs={12} md={6}>
                <Box display="flex" gap={2}>
                  {/* Title Field */}
                  <InputField
                    label="Title"
                    value={formik.values.memberNameTitle}
                    onChange={formik.handleChange}
                    name="memberNameTitle"
                    select
                    error={
                      formik.touched.memberNameTitle &&
                      !!formik.errors.memberNameTitle
                    }
                    helperText={
                      formik.touched.memberNameTitle &&
                      formik.errors.memberNameTitle
                    }
                    required
                  >
                    <MenuItem value="MR">Mr.</MenuItem>
                    <MenuItem value="MS">Ms.</MenuItem>
                    <MenuItem value="MRS">Mrs.</MenuItem>
                    <MenuItem value="Smt">Smt</MenuItem>
                    <MenuItem value="MD">MD</MenuItem>
                  </InputField>

                  <InputField
                    label="Member Name"
                    value={formik.values.memberName}
                    onChange={(e) => {
                      const upperCaseValue = e.target.value.toUpperCase();
                      formik.setFieldValue("memberName", upperCaseValue);
                    }}
                    name="memberName"
                    error={
                      formik.touched.memberName && !!formik.errors.memberName
                    }
                    helperText={
                      formik.touched.memberName && formik.errors.memberName
                    }
                    required
                  />
                </Box>
              </Grid>

              <InputField
                label="Aadhar No."
                value={formik.values.aadharNo}
                onChange={formik.handleChange}
                error={formik.touched.aadharNo && !!formik.errors.aadharNo}
                helperText={formik.touched.aadharNo && formik.errors.aadharNo}
                name="aadharNo"
                required
              />
              <InputField
                label="PAN No."
                value={formik.values.panNo}
                onChange={formik.handleChange}
                error={formik.touched.panNo && !!formik.errors.panNo}
                helperText={formik.touched.panNo && formik.errors.panNo}
                name="panNo"
              />
              <InputField
                label="Voter No."
                value={formik.values.voterNo}
                onChange={formik.handleChange}
                error={formik.touched.voterNo && !!formik.errors.voterNo}
                helperText={formik.touched.voterNo && formik.errors.voterNo}
                name="voterNo"
              />
              <InputField
                label="Gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && !!formik.errors.gender}
                helperText={formik.touched.gender && formik.errors.gender}
                name="gender"
                select
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </InputField>
              <InputField
                label="Mobile No."
                value={formik.values.mobileNo}
                onChange={formik.handleChange}
                error={formik.touched.mobileNo && !!formik.errors.mobileNo}
                helperText={formik.touched.mobileNo && formik.errors.mobileNo}
                name="mobileNo"
                required
              />
              <Grid item xs={12} md={6}>
                <Box display="flex" gap={2}>
                  <InputField
                    label="DOB"
                    type="date"
                    value={formik.values.dob}
                    onChange={(e) => {
                      formik.handleChange(e);
                      const birthDate = new Date(e.target.value);
                      const today = new Date();
                      const calculatedAge =
                        today.getFullYear() - birthDate.getFullYear();
                      formik.setFieldValue("age", calculatedAge);
                    }}
                    name="dob"
                    error={formik.touched.dob && !!formik.errors.dob}
                    helperText={formik.touched.dob && formik.errors.dob}
                    required
                  />
                  <InputField
                    label="Age"
                    value={formik.values.age}
                    onChange={formik.handleChange}
                    error={formik.touched.age && !!formik.errors.age}
                    helperText={formik.touched.age && formik.errors.age}
                    name="age"
                    disabled
                  />
                </Box>
              </Grid>
              <InputField
                label="Email ID"
                value={formik.values.emailId}
                onChange={formik.handleChange}
                error={formik.touched.emailId && !!formik.errors.emailId}
                helperText={formik.touched.emailId && formik.errors.emailId}
                name="emailId"
              />
              <InputField
                label="Marital Status"
                value={formik.values.martialStatus}
                onChange={formik.handleChange}
                error={
                  formik.touched.martialStatus && !!formik.errors.martialStatus
                }
                helperText={
                  formik.touched.martialStatus && formik.errors.martialStatus
                }
                name="martialStatus"
                select
              >
                <MenuItem value="SINGLE">Single</MenuItem>
                <MenuItem value="MARRIED">Married</MenuItem>
                <MenuItem value="UNMARRIED">Unmarried</MenuItem>
                <MenuItem value="DIVORCED">Divorced</MenuItem>
                <MenuItem value="WIDOWED">Widowed</MenuItem>
                <MenuItem value="SEPARATED">SEPARATED</MenuItem>
              </InputField>
              <InputField
                label="Occupation"
                value={formik.values.occupation}
                onChange={formik.handleChange}
                error={formik.touched.occupation && !!formik.errors.occupation}
                helperText={
                  formik.touched.occupation && formik.errors.occupation
                }
                name="occupation"
              />
              <InputField
                label="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && !!formik.errors.address}
                helperText={formik.touched.address && formik.errors.address}
                name="address"
                required
              />
              <InputField
                label="Education"
                value={formik.values.education}
                onChange={formik.handleChange}
                error={formik.touched.education && !!formik.errors.education}
                helperText={formik.touched.education && formik.errors.education}
                name="education"
              />
              <InputField
                label="District"
                value={formik.values.district}
                onChange={formik.handleChange}
                error={formik.touched.district && !!formik.errors.district}
                helperText={formik.touched.district && formik.errors.district}
                name="district"
                required
              />
              <InputField
                label="Intro.M Code"
                value={formik.values.introMemCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.introMemCode && !!formik.errors.introMemCode
                }
                helperText={
                  formik.touched.introMemCode && formik.errors.introMemCode
                }
                name="introMemCode"
              />
              <InputField
                label="State"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && !!formik.errors.state}
                helperText={formik.touched.state && formik.errors.state}
                name="state"
                required
              />
              <InputField
                label="Branch Name"
                value={formik.values.branchName}
                onChange={formik.handleChange}
                error={formik.touched.branchName && !!formik.errors.branchName}
                helperText={
                  formik.touched.branchName && formik.errors.branchName
                }
                name="branchName"
                select
                required
              >
                {branches.map((branch) => (
                  <MenuItem key={branch?.id} value={branch?.branchName}>
                    {`${branch?.branchName} - ${branch?.branchCode}`}
                  </MenuItem>
                ))}
              </InputField>
            </Grid>
          </Paper>

          <Paper sx={{ ...styles.paper, mt: 2 }}>
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Nominee Details
            </Typography>
            <Grid container spacing={2}>
              <InputField
                label="Nominee Name"
                value={formik.values.nomineeName}
                onChange={formik.handleChange}
                error={
                  formik.touched.nomineeName && !!formik.errors.nomineeName
                }
                helperText={
                  formik.touched.nomineeName && formik.errors.nomineeName
                }
                name="nomineeName"
                required
              />
              <InputField
                label="Nominee Mobile No."
                value={formik.values.nomineemobileNo}
                onChange={formik.handleChange}
                error={
                  formik.touched.nomineemobileNo &&
                  !!formik.errors.nomineemobileNo
                }
                helperText={
                  formik.touched.nomineemobileNo &&
                  formik.errors.nomineemobileNo
                }
                name="nomineemobileNo"
              />
              <InputField
                value={formik.values.nomineeRelation}
                onChange={formik.handleChange}
                error={
                  formik.touched.nomineeRelation &&
                  !!formik.errors.nomineeRelation
                }
                helperText={
                  formik.touched.nomineeRelation &&
                  formik.errors.nomineeRelation
                }
                name="nomineeRelation"
                label="Nominee Relation"
                select
              >
                <MenuItem value="FATHER">FATHER</MenuItem>
                <MenuItem value="MOTHER">MOTHER</MenuItem>
                <MenuItem value="BROTHER">BROTHER</MenuItem>
                <MenuItem value="SISTER">SISTER</MenuItem>
                <MenuItem value="HUSBAND">HUSBAND</MenuItem>
                <MenuItem value="WIFE">WIFE</MenuItem>
                <MenuItem value="SON">SON</MenuItem>
                <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
                <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
                <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
                <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
                <MenuItem value="GRANDSON">GRANDSON</MenuItem>
                <MenuItem value="OTHER">OTHER</MenuItem>
              </InputField>
              <InputField
                value={formik.values.nomineeAge}
                onChange={formik.handleChange}
                error={formik.touched.nomineeAge && !!formik.errors.nomineeAge}
                helperText={
                  formik.touched.nomineeAge && formik.errors.nomineeAge
                }
                name="nomineeAge"
                label="Nominee Age"
              />
            </Grid>
          </Paper>

          <Paper sx={{ ...styles.paper, mt: 2 }}>
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Fees/Setting Details
            </Typography>
            <Grid container spacing={2}>
              <InputField
                value={formik.values.memberfees}
                onChange={formik.handleChange}
                error={formik.touched.memberfees && !!formik.errors.memberfees}
                helperText={
                  formik.touched.memberfees && formik.errors.memberfees
                }
                name="memberfees"
                label="Member Fess(if any)"
              />
              <InputField
                value={formik.values.promotorCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.promotorCode && !!formik.errors.promotorCode
                }
                helperText={
                  formik.touched.promotorCode && formik.errors.promotorCode
                }
                name="promotorCode"
                label="Share Allotted From"
                select
                required
              >
                {promotors.map((promotor) => (
                  <MenuItem
                    key={promotor?.memberCode}
                    value={promotor?.memberCode || ""}
                  >
                    {`${promotor?.memberName} - ${promotor?.memberCode}`}
                  </MenuItem>
                ))}
              </InputField>
              <InputField
                label="No. Of Share"
                value={formik.values.noOfShare}
                onChange={(e) => {
                  const noOfShare = parseInt(e.target.value || 0, 10);
                  formik.setFieldValue("noOfShare", noOfShare);
                  const calculatedShareAmount = noOfShare * 10;
                  formik.setFieldValue("shareAmount", calculatedShareAmount);
                }}
                error={formik.touched.noOfShare && !!formik.errors.noOfShare}
                helperText={formik.touched.noOfShare && formik.errors.noOfShare}
                name="noOfShare"
                required
              />

              <InputField
                label="Share Amount"
                value={formik.values.shareAmount}
                error={
                  formik.touched.shareAmount && !!formik.errors.shareAmount
                }
                helperText={
                  formik.touched.shareAmount && formik.errors.shareAmount
                }
                name="shareAmount"
                disabled
                required
              />

              <InputField
                value={formik.values.paymentBy}
                onChange={formik.handleChange}
                error={formik.touched.paymentBy && !!formik.errors.paymentBy}
                helperText={formik.touched.paymentBy && formik.errors.paymentBy}
                name="paymentBy"
                label="Payment By"
                select
                required
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                <MenuItem value="ONLINE">ONLINE</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
              </InputField>
              <InputField
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && !!formik.errors.remarks}
                helperText={formik.touched.remarks && formik.errors.remarks}
                name="remarks"
                label="Remarks"
              />
            </Grid>
            <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
              <Button
                type="submit"
                sx={{ ...styles.button, ...styles.saveButton }}
              >
                Update
              </Button>
              {/* <Button sx={{ ...styles.button, ...styles.deleteButton }}>
                Delete
              </Button> */}
            </Box>
          </Paper>
        </>
      </Box>
    </form>
  );
}

export default MemberRectification;

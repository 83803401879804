import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Switch,
} from "@mui/material";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

function ShareRectification() {
  return (
    <Box>
      <Typography sx={styles.header}>Share Delete</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Share Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Search by Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Branch <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Member Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Transfer Date <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="date"
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Date Of Join</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Share Allotted From <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Previous Balance</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Share Balance</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Previous No Of Share</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Transfer Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Face Value</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              No. of Share <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Payment Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Payment By <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Remarks</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
          <Button sx={{ ...styles.button, ...styles.newButton }}>New</Button>
          <Button sx={{ ...styles.button, ...styles.deleteButton }}>
            Delete
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ShareRectification;

import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const InactivityHandler = ({ children, timeout = 30 * 60 * 1000 }) => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const navigate = useNavigate();

  const resetTimer = useCallback(() => {
    setLastActivity(Date.now());
  }, []);

  useEffect(() => {
    const handleActivity = () => resetTimer();
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    events.forEach((event) => window.addEventListener(event, handleActivity));

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
    };
  }, [resetTimer]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastActivity >= timeout) {
        localStorage.clear();
        navigate("/login", {
          state: { message: "You have been logged out due to inactivity." },
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastActivity, timeout, navigate]);

  return children;
};

export default InactivityHandler;

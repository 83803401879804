import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import empidtop from "../../Assets/empidtop.svg";
import empidbottom from "../../Assets/empidbottom.svg";
import idimg from "../../Assets/idimg.svg";
import harsh from "../../Assets/harsh.png";
// import ashutosh from "../../Assets/ashutosh.png";
import empqr from "../../Assets/empqr.svg";
import CompanyName from "../../Assets/CompanyName&Logo.svg";
import vllogo from "../../Assets/logo.png";

function EmployeeIDCard() {
  const [showTable, setShowTable] = useState(false);
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Employee ID Card
      </Typography>

      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Search Box
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                mb: 1,
                mt: 2,
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
              }}
            >
              Employee Code :
            </Typography>
            <TextField
              sx={{
                width: { xs: "100%", md: "495px" },
                height: "48px",
                borderRadius: "10px",
                borderColor: "#D9D9D9CC",
                backgroundColor: "#FFFFFF",
                "& .MuiInputBase-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#DFBF41",
                width: { xs: "50%", md: "157px" },
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#B89B3E" },
                mt: 5,
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Paper>

      <Paper sx={{ p: 2, mt: 2, borderRadius: "20px" }}>
        <Box sx={{ mt: 0 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 2,
            }}
          >
            Search Result
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            {/* ID Front */}
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "322px",
              }}
            >
              <Box
                component="img"
                src={empidtop}
                alt="empidtop"
                sx={{ width: "204px" }}
              />

              <Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {/* <Box
                    component="img"
                    src={idimg}
                    alt="idimg"
                    sx={{
                      width: "70px",
                      height: "70px",
                    }}
                  /> */}
                  <Box
                    component="img"
                    src={harsh}
                    alt="idimg"
                    sx={{
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ my: 1.5 }}>
                <Table size="small">
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "8px",
                        fontWeight: 700,
                        borderBottom: "none",
                      }}
                    >
                      Name:
                    </TableCell>
                    <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                      HARSH RAVINDRA JARARE
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "8px",
                        fontWeight: 700,
                        borderBottom: "none",
                      }}
                    >
                      Code:
                    </TableCell>
                    <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                      VL00067
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "8px",
                        fontWeight: 700,
                        borderBottom: "none",
                      }}
                    >
                      Issue Date:
                    </TableCell>
                    <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                      18/07/2023
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "8px",
                        fontWeight: 700,
                        borderBottom: "none",
                      }}
                    >
                      Branch:
                    </TableCell>
                    <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                      MAIN OFFICE
                    </TableCell>
                  </TableRow>
                </Table>
              </Box>
              <Box
                component="img"
                src={empqr}
                alt="empqr"
                sx={{ width: "100px", height: "20px" }}
              />
              <Box
                component="img"
                src={empidbottom}
                alt="empidtop"
                sx={{ width: "204px" }}
              />
            </Paper>

            {/* ID Back */}
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "322px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* <Box
                    component="img"
                    src={CompanyName}
                    alt="CompanyName"
                    sx={{ width: "85px", height: "45px", mt: 4 }}
                  /> */}
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Box
                      component="img"
                      src={vllogo}
                      alt="VL_Logo"
                      sx={{ width: "50px", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                      VENKETLAXMI URBAN
                    </Typography>
                    <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                      PUNE NIDHI LTD
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Table size="small">
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "8px",
                          fontWeight: 700,
                          borderBottom: "none",
                        }}
                      >
                        Address:
                      </TableCell>
                      <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                        Pune
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "8px",
                          fontWeight: 700,
                          borderBottom: "none",
                        }}
                      >
                        Designation:
                      </TableCell>
                      <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                        Full Stack Dev
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "8px",
                          fontWeight: 700,
                          borderBottom: "none",
                        }}
                      >
                        DOB:
                      </TableCell>
                      <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                        18/09/1995
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "8px",
                          fontWeight: 700,
                          borderBottom: "none",
                        }}
                      >
                        Mobile:
                      </TableCell>
                      <TableCell sx={{ fontSize: "8px", borderBottom: "none" }}>
                        8299144486
                      </TableCell>
                    </TableRow>
                  </Table>
                </Box>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    textAlign: "center",
                    mt: 1.5,
                  }}
                >
                  --------------------
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  Your Signature
                </Typography>
              </Box>
              <Box
                component="img"
                src={empidbottom}
                alt="empidtop"
                sx={{ width: "204px" }}
              />
            </Paper>
          </Box>
        </Box>
      </Paper>
      {/* )} */}
    </Box>
  );
}

export default EmployeeIDCard;

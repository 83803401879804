import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Switch,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

const InputField = ({
  label,
  type = "text",
  required = false,
  error,
  helperText,
  ...props
}) => (
  <Grid item xs={12} md={6}>
    <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
    <TextField
      type={type}
      sx={{
        width: "100%",
        borderColor: "#D9D9D9CC",
        backgroundColor: "#FFFFFF",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
        },
      }}
      InputProps={{
        style: { borderRadius: "10px" },
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  </Grid>
);

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  header: {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "118px" },
    height: "48px",
    fontWeight: "bold",
  },
  newButton: {
    backgroundColor: "#05ACD6",
    color: "#fff",
    ":hover": { background: "#049ac0" },
  },
  saveButton: {
    backgroundColor: "#E8C30B",
    color: "#fff",
    ":hover": { background: "#d0af09" },
  },
  deleteButton: {
    backgroundColor: "#92230C",
    color: "#fff",
    ":hover": { background: "#831F0A" },
  },
};

function SavingsRectification() {
  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return (
    <Box>
      <Typography sx={styles.header}>Edit SB Account</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Search Details
        </Typography>
        <Grid container spacing={2}>
          <InputField label="Select by Account No." select required />
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Saving Details
        </Typography>
        <Grid container spacing={2}>
          <InputField label="Opening Date" type="date" required />
          <InputField label="Branch Name" select required />
          <InputField label="Select Member" select required />
          <InputField label="State" required />
          <InputField label="Member Name" select required />
          <InputField label="Pin Code" required />
          <Grid item xs={12} md={6}>
            <Box display="flex" gap={2}>
              <InputField label="DOB" type="date" required />
              <InputField label="Age" disabled />
            </Box>
          </Grid>
          <InputField label="Mode of Operation" select required />
          <InputField label="Relative Name" />
          <InputField label="Relative Relation" select />
          <InputField label="Joint/Survivor Code" select />
          <InputField label="Mobile No" required />
          <InputField label="Joint/Survivor Name" select />
          <InputField label="Nominee Name" />
          <InputField label="Joint/Survivor Relation" />
          <InputField label="Nominee Age" select required />
          <InputField label="Select Plan" select required />
          <InputField label="Address" select required />
          <InputField label="District" required />
          <InputField label="Occupation" />
          <InputField label="Education" />
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Introducer Details
        </Typography>
        <Grid container spacing={2}>
          <InputField label="Select Position" select required />
          <InputField label="Introducer Name" disabled />
          <InputField label="Introducer Code" required />
          <InputField label="Position" required disabled />
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          Payment Details
        </Typography>
        <Grid container spacing={2}>
          <InputField label="Fees (if any)" required />
          <InputField label="Payment By" select required />
          <InputField label="Remarks" />
          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>Advisor Status</Typography>
            <Switch sx={{ transform: "scale(1.5)" }} />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>SMS Send</Typography>
            <Switch sx={{ transform: "scale(1.5)" }} />
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={1}>
          <Button sx={{ ...styles.button, ...styles.saveButton }}>
            Update
          </Button>
          <Button sx={{ ...styles.button, ...styles.deleteButton }}>
            Delete
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default SavingsRectification;
